import { ReactComponent as Offer } from '../../../../Images/Icons/Offer.svg';
export const leftButtons = () => [{
    key: 'LblTitle',
    text: <div className='row'>
        <div className='col-lg-12 col-md-12'>
            <Offer className="color-info-fill" />
            <span className="home-header-text-button">
                Nueva oferta por descuento
            </span>
        </div>
    </div>,
    fontSize: '16px',
    isText: true
}];
export const rightButtons = (handleClickSave, handleClickReturn) => [{
    key: 'BtnGuardar',
    text: 'Guardar',
    iconName: 'saveCuvitek',
    fontColor: '#FFFFFF',
    iconColor: '#fff',
    iconHover: '#fff',
    backgroundColorButton: 'var(--success)',
    backgroundColorButtonHover: 'var(--success)',
    isButton: true,
    handler: handleClickSave
}
    , {
    key: 'BtnRegresar',
    text: 'Regresar',
    iconName: 'returnCuvitek',
    iconColor: 'var(--secondary)',
        iconHover: 'var(--primary)',
        handler: handleClickReturn
}]