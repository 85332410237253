import { OmniCard } from "../../../Shared/Card/OmniCard";
import { Radio, Label, RadioGroup } from "@fluentui/react-components";
import { OmniTextBox } from '../../../Shared/Controls/TextBox/OmniTextBox';
import { ItemsTablePriceList } from './ItemsTablePriceList';
import { ListPriceTable } from '../ListPriceTable';
export const Discount = (props) => {
    const onChangeDiscountType = (event, data) => {
        if (props.discountOffer?.priceList) {
            props.setDiscountOffer((prev) => ({
                ...prev,
                priceList: prev.priceList.map((item) => {
                    return {
                        ...item,
                        listForPrice: item.listForPrice.map((itemPrice) => {
                            return {
                                ...itemPrice,
                                discount: 0,
                                finalPriceWithTaxes: itemPrice.priceWithTaxes,
                                finalProfit: itemPrice.profit
                            };
                        }),
                        negative: 0
                    };
                }),
                discountType: data.value,
                discount: ''
            }))
        }
        else {
            props.setDiscountOffer(prevEdit => ({ ...prevEdit, discountType: data.value, discount: '' }));
        }
    };
    const onChangeDiscount = (event, data) => {
        if (props.discountOffer?.priceList && event.target.value) {
            props.setDiscountOffer((prev) => ({
                ...prev,
                priceList: prev.priceList.map((item) => {
                    let negative = 0;
                    return {
                        ...item,
                        listForPrice: item.listForPrice.map((itemPrice) => {
                            if (itemPrice.hasPrice === true) {
                                let discount = event.target.value.replace("%", "");
                                let priceWithTaxes = parseFloat(itemPrice.priceWithTaxes)
                                let finalPriceWithTaxes = priceWithTaxes - (parseFloat(discount) * priceWithTaxes / 100)
                                let finalProfit = (finalPriceWithTaxes - itemPrice.packagePieceCostWithTaxes).toFixed(2)
                                if (finalProfit <= 0) {
                                    negative++;
                                }
                                return {
                                    ...itemPrice,
                                    discount: discount,
                                    finalPriceWithTaxes: finalPriceWithTaxes.toFixed(2),
                                    finalProfit: finalProfit
                                };
                            }
                            else { return itemPrice}
                        }),
                        negative: negative
                    };
                })
            })) 
        }
    }
    return (
        <>
            <div className='row container-information'>
                <div className='col-md-4'>
                    <OmniCard title="Listas de precios" classCard='card-omni-without-width'>
                        <div className='row'>
                            <div className='col-12'>
                                <Label weight="semibold">Aplicación del descuento</Label>
                            </div>
                        </div>
                        <div className='row'>
                            <RadioGroup layout={"horizontal"}
                                onChange={onChangeDiscountType}
                                defaultValue={props.discountOffer?.discountType}
                                value={props.discountOffer?.discountType} title=""
                                className='radio-group-omni'>
                                <div className='col-4'>
                                    <Radio
                                        value="1"
                                        label="General del"
                                    />
                                </div>
                                <div className='col-3'>
                                    {props.discountOffer?.discountType === '1' &&
                                        <OmniTextBox title="" property="discount" max={99.99}
                                            edit={props.setDiscountOffer} value={props.discountOffer?.discount} suffix="%" inputType={'decimal'}
                                        decimals={2} withoutfiel classInput="textbox-discount" onChange={onChangeDiscount}/>
                                    }
                                </div>
                                <div className='col-5'>
                                    <Radio
                                        value="2"
                                        label="Individual"
                                    />
                                </div>
                            </RadioGroup>
                        </div>
                        {props.discountOffer?.priceList  &&
                            <div className='row card-items-container' >
                                <div className='col-lg-12 col-md-12'>
                                    <ItemsTablePriceList
                                        titleheader={"Listas de precios que aplican a la selección (" + (props.discountOffer?.priceList?.length) + ")"}
                                        items={props.discountOffer?.priceList} setItems={props.setDiscountOffer} property={"priceList"} 
                                    />
                                </div>
                            </div>
                        }
                    </OmniCard>
                </div>
                <div className='col-md-8'>
                    <OmniCard title={(
                        <span>
                            Lista de precios:   <span className="title-pricelist">{props.discountOffer?.priceList?.find(x => x.selected === true)?.name || ''}</span>
                        </span>)} classCard='card-omni-without-width'>
                        {props.discountOffer?.priceList?.find(x => x.selected === true).listForPrice &&
                            <div className='row card-items-container-discount' >
                                <div className='col-lg-12 col-md-12'>
                                    <ListPriceTable items={props.discountOffer?.priceList?.find(x => x.selected === true).listForPrice} setItems={props.setDiscountOffer} 
                                        discountType={props.discountOffer?.discountType}/>
                                </div>
                            </div>
                        }
                    </OmniCard>
                </div>
            </div>
        </>
    )
}