const pricelistEndpoint = {
    controller: "pricelist",
    actions: {
        priceListOfferById: "PriceListOfferById",
    }
}
var axiosPrivate;
export const SetAxiosPrivate = (axiosPrivateParam) => {
    axiosPrivate = axiosPrivateParam;
}
export const PriceListOfferByIdAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.post(getUrl(pricelistEndpoint.controller, pricelistEndpoint.actions.priceListOfferById).url,
            JSON.stringify({
                itemSku: data.itemSku,
                priceListId: data.priceListId,
                discount: data.discount
            }));
        if (response.data.isSuccessful) {
            if (response.data && response.data.data) {
                result.data = response.data.data;
            }
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
/*              PRIVATE FUNC            */
function getUrl(controller, action) {
    var result = {
        isSuccessful: false,
        url: "",
        errorMessage: ""
    }
    if (controller && action) {
        result.url = ("/" + controller + "/" + action);
    } else {
        result.errorMessage = "Controller or action undefined.";
    }

    return result;
}

const getErrorText = (err) => {
    if (!err?.response) {
        return "No server response";
    } else if (err.response?.status === 400) {
        return err.response?.data?.errorMessage ?? err.response?.data.title
    }
    else if (err.response?.status === 500) {
        return err.response?.data?.detail ?? (err.message + "  (" + err.code + ")");
    }
}