import {
    OverlayDrawer, DrawerBody, DrawerHeader, DrawerHeaderTitle,
    DrawerFooter, Image, CompoundButton
} from "@fluentui/react-components";
import { OmniTextBox } from "../../../Shared/Controls/TextBox/OmniTextBox";
import { OmniLabel } from '../../../Shared/Controls/Label/OmniLabel';
import { OmniCheckBox } from '../../../Shared/Controls/Checkbox/OmniCheckBox'
import { useState, useEffect } from 'react';
import BarMessageList from "../../../Shared/BarMessages/BarMessageList";
import { InfoMessage } from '../../../../Models/Shared/BarMessages/Messages';
import { GetBranchsAsync } from '../../../../Functions/Branchs/BranchStore';
import {
    SetAxiosPrivate as SetAxiosPrivateList, PriceListOfferByIdAsync
} from '../../../../Functions/Catalogs/PriceLists/PriceListStore';
import useAxiosPrivate from '../../../../Hooks/Common/useAxiosPrivate'
const returnWhite = require('../../../../Assets/Images/Icons/ReturnWhite.svg').default;
const apply = require('../../../../Assets/Images/Icons/Apply.svg').default;
export const OverlayDrawerBranch = (props) => {
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivateList(axiosPrivate);
    const [messages, setMessages] = useState([]);
    const onChangeBranchGroupTypeText = (event) => {
        const value = event.target.value.toLowerCase().trim();
        if (value) {
            props.setBranchGroupList((prev) => {
                const updated = prev.map((item) => {
                    if (props?.param?.branchType === '2' && props?.param?.branchesType === "2") {
                        return {
                            ...item,
                            listBranchOfferResult: item.listBranchOfferResult.map((itemBranch) => {
                                if (itemBranch.name.toLowerCase().includes(value)) {
                                    return {
                                        ...itemBranch,
                                        filter: undefined
                                    };
                                }
                                else {
                                    return {
                                        ...itemBranch,
                                        filter: false
                                    };
                                }
                            })
                        };

                    }
                    else if (item.name.toLowerCase().includes(value)) {
                        return {
                            ...item,
                            filter: undefined
                        };
                    }
                    else {
                        return {
                            ...item,
                            filter: false
                        };
                    }
                });
                return updated;
            });
        }
        else {
            onCleanTextBranchGroup();
        }
    };
    const onChangeCheckBoxBranchGroup = (event, data) => {
        if (data.checked) {
            props.setBranchGroupList((prev) => {
                const updated = prev.map((item) => {
                    if (props?.param?.branchType === '2' && props?.param?.branchesType === "2") {
                        return {
                            ...item,
                            listBranchOfferResult: item.listBranchOfferResult.map((itemBranch) => {
                                if (itemBranch.id == event.target.id) {
                                    return {
                                        ...itemBranch,
                                        selected: true
                                    };
                                }
                                return itemBranch;
                            })
                        };

                    }
                    else if (item.id == event.target.id) {
                        return {
                            ...item,
                            selected: true
                        };
                    }
                    return item;
                });
                return updated;
            });
        }
        else {
            props.setBranchGroupList((prev) => {
                const updated = prev.map((item) => {
                    if (props?.param?.branchType === '2' && props?.param?.branchesType === "2") {
                        return {
                            ...item,
                            listBranchOfferResult: item.listBranchOfferResult.map((itemBranch) => {
                                if (itemBranch.id == event.target.id) {
                                    return {
                                        ...itemBranch,
                                        selected: false
                                    };
                                }
                                return itemBranch;
                            })
                        };

                    }
                    else if (item.id == event.target.id) {
                        return {
                            ...item,
                            selected: false
                        };
                    }
                    return item;
                });
                return updated;
            });
        }
    };
    const addBranchGroup = async () => {
        if (props.branchGroupList.some(x => x.selected) || props.branchGroupList.some(x => x.listBranchOfferResult.some(y => y.selected))) {
            props.setShowAttachBranchGroupPanel(false);
            onCleanTextBranchGroup();
            const branchGroup = props.branchGroupList.filter(x => x.selected === true || x.listBranchOfferResult.some(y => y.selected)).map(x => ({ id: x.id, description: x.name, priceListId: x.priceListId }))
            const distinctElements = branchGroup.reduce((acc, element) => {
                if (!acc.some(accElement => accElement.priceListId === element.priceListId)) {
                    acc.push({
                        priceListId: element.priceListId
                    });
                }
                return acc;
            }, []);
           
            const listSelected = distinctElements.map(async x => {
                    const getPriceListParam = {
                        itemSku: props.param.offerType === "2" ? props.param.itemVarietyId : props.param.itemId,
                        priceListId: x?.priceListId,
                        discount: props.param.discount ? props.param.discount.replace("%", "") : 0
                    }
                let result = await PriceListOfferByIdAsync(getPriceListParam)
                if (result.isSuccessful) {
                    if (result.data.listPriceOfferResults && result.data.listPriceOfferResults.length > 0) {
                        return { id: x.priceListId, name: result.data.name, rate: result.data.rate, listForPrice: result.data.listPriceOfferResults, negative: result.data.listPriceOfferResults.filter(x => parseFloat(x.finalProfit) <= 0 && x.hasPrice === true).length }
                    }
                    else {
                        return { id: x.priceListId, name: result.data.name, rate: result.data.rate, listForPrice: undefined, negative: 0 }
                    }
                }
            }).filter(item => item !== undefined);
            
            const updatedPriceList = await Promise.all(listSelected);
            if (updatedPriceList) {
                updatedPriceList[0].selected = true;
            }
            console.log(branchGroup);
            console.log("pruebas");

            let resultArray = [];

            props.branchGroupList.forEach(item => {
                item.listBranchOfferResult.forEach(itemBranch => {
                    if (itemBranch.selected) {
                        resultArray.push({
                            id: itemBranch.id,
                            description: itemBranch.name,
                            priceListId: item.priceListId
                        });
                    }
                });
            });
            console.log(resultArray);
            if (props?.param?.branchType === '2' && props?.param?.branchesType === "2") {
                props.setParam((prev) => ({
                    ...prev,
                    branchGroup: resultArray,
                    priceList: updatedPriceList ?? undefined
                }))
            }
            else {
                props.setParam((prev) => ({
                    ...prev,
                    branchGroup: branchGroup,
                    priceList: updatedPriceList ?? undefined
                }))
            }
        }
        else {
            setMessages([new InfoMessage(`Debe seleccionar ${props?.param?.branchType === '2' ? 'una sucursal' : 'un grupo de sucursales'}`)]);
        }
    };
    const onCleanTextBranchGroup = () => {
        props.setBranchGroupList((prev) => {
            const updated = prev.map((item) => {
                return {
                    ...item,
                    filter: undefined,
                    listBranchOfferResult: item.listBranchOfferResult.map((itemBranch) => {
                        return {
                            ...itemBranch,
                            filter: undefined
                        };
                    })
                };
            });
            return updated;
        });
    };
    const CloseOverlayDrawer = () => {
        props.setShowAttachBranchGroupPanel(false);
        props.setBranchGroupList(prevEdit => prevEdit.map((BranchGroup) => ({
            ...BranchGroup,
            selected: false,
            filter: undefined,
            listBranchOfferResult: BranchGroup.listBranchOfferResult.map((itemBranch) => {
                return {
                    ...itemBranch,
                    selected: false,
                    filter: undefined,
                };
            })
        })))
        props.setParam(prevEdit => ({ ...prevEdit, branchGroupType: "1", branchGroup: undefined, branchesType: '1', branches: undefined }));
    };
  
    const GetBranchs = async () => {
        let resultBranch = await GetBranchsAsync();
            if (resultBranch.isSuccessful) {
                props.setBranchGroupList((prev) => {
                    const updated = prev.map((item) => {
                        return {
                            ...item,
                            listBranchOfferResult: item.listBranchOfferResult.map((itemBranch) => {
                                return {
                                    ...itemBranch,
                                    name: resultBranch.data.find(y => y.key === itemBranch.cuvikBranchId).text
                                };
                            })
                        };
                    });
                    return updated;
                });
            }
        
    }
    useEffect(() => {
        if (props?.param?.branchType === '2' && props?.param?.branchesType === "2") {
            GetBranchs()
        }
    }, [props?.param?.branchesType])
    useEffect(() => {
        setMessages([]);
    }, [props.showAttachBranchGroupPanel])
    return (
        <OverlayDrawer position="end" open={props.showAttachBranchGroupPanel} modalType="alert"
            style={{ width: '896px' }}>
            <DrawerHeader>
                <DrawerHeaderTitle>
                    <div className='row'>
                      
                        <div className='col-12'>
                            {props?.param?.branchType === '2' ?"Sucursales": "Grupo de sucursales"}
                            
                        </div>
                    </div>

                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody>
                <BarMessageList messages={messages} />
                    <div className='row mt-2'>
                        <div className='col-md-6'>
                        <OmniTextBox focus title={props?.param?.branchType === '2' ? "Búsqueda de sucursales" : "Búsqueda de grupos de sucursales"} maxLength={64} onChange={onChangeBranchGroupTypeText} inactivefieldEdited />
                            <div className='row overflow-panel'>
                                <div className='col-md-12'>
                                {props?.param?.branchType === '2' && props?.param?.branchesType === "2" ?
                                    props.branchGroupList.map((item) => (
                                        item.listBranchOfferResult.map((itemBranch) => (
                                            itemBranch?.filter === undefined &&
                                            <OmniCheckBox title={itemBranch.name} inactivefieldEdited onChange={onChangeCheckBoxBranchGroup} id={itemBranch.id} key={itemBranch.id} name={itemBranch.name} propertyChecked={itemBranch.selected} />

                                        ))))
                                :
                                    props.branchGroupList.map((item) => (
                                            item?.filter === undefined &&
                                            <OmniCheckBox title={item.name} inactivefieldEdited onChange={onChangeCheckBoxBranchGroup} id={item.id} key={item.id} name={item.name} propertyChecked={item.selected} />
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                        <OmniLabel text={props?.param?.branchType === '2' ? "Sucursales seleccionadas" : "Grupos de sucursales seleccionados"} />
                            <div className='row overflow-panel'>
                                <div className='col-md-12'>
                                {props?.param?.branchType === '2' && props?.param?.branchesType === "2" ?
                                    props.branchGroupList.map((item) => (
                                        item.listBranchOfferResult.map((itemBranch) => (
                                            itemBranch.selected === true &&
                                            <OmniCheckBox title={itemBranch.name} inactivefieldEdited onChange={onChangeCheckBoxBranchGroup} id={itemBranch.id} key={itemBranch.id} propertyChecked={true} />

                                        ))))
                                    :
                                    props.branchGroupList.map((item) => (
                                        item.selected === true &&
                                        <OmniCheckBox title={item.name} inactivefieldEdited onChange={onChangeCheckBoxBranchGroup} id={item.id} key={item.id} propertyChecked={true} />

                                    ))
                                }
                                </div>
                            </div>
                        </div>
                    </div>
            </DrawerBody>
            <DrawerFooter >
                <div className="drawer-footer-container">
                    <CompoundButton id="btnCloseAttachSqlPanel" className="tile-button-panel"
                        icon={<Image src={returnWhite} alt="return" />}
                        iconPosition="after"
                        shape="square" size="small"
                        onClick={CloseOverlayDrawer}
                    >Cerrar</CompoundButton>

                    <CompoundButton id="btnAddSqQueryPanel" className="tile-button-panel tile-button-panel-primary"
                        icon={<Image src={apply} alt="apply" />}
                        iconPosition="after"
                        shape="square" size="small"
                        onClick={addBranchGroup}
                    >Aplicar</CompoundButton>
                </div>
            </DrawerFooter>
        </OverlayDrawer>
    )
}