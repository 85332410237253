import { Button, Image, Table, TableBody, TableCell, TableCellLayout, TableHeader, 
    TableHeaderCell, TableRow, Tooltip, makeStyles } from "@fluentui/react-components";
import { OmniCard } from "../../../Shared/Card/OmniCard";
import { OmniLabel } from "../../../Shared/Controls/Label/OmniLabel";
import {ReactComponent as PieceIcon} from "../../../../Assets/Images/Icons/Piece.svg";
import {ReactComponent as BoxIcon} from "../../../../Assets/Images/Icons/Box.svg";
import { AlixMeasure } from "../../../../Assets/Constants/Enums/AlixMeasure";
import { OmniTextBox } from "../../../Shared/Controls/TextBox/OmniTextBox";
import { OmniSwitch } from '../../../Shared/Controls/Switch/OmniSwitch';
import { useState } from "react";
const trashIcon = require('../../../../Assets/Images/Icons/Trash.svg').default;
const stylesTooltipAll = makeStyles({
    tooltip: {
        backgroundColor: 'var(--primary)',
        color: 'white',
    }
});

export default function KeysAndcodes(props){
    const stylesTooltip = stylesTooltipAll();
    const[aditionalCode, setAditionalCode]=useState("");
    const[aditionalMeasure, setAditionalMeasure]=useState(false);
    const [showErrors, setShowErrors] = useState({
        clave: undefined,
        name: undefined,
    });

    const onBlurAditionalCode=(event, data)=>{
        var value=event.target.value;
        if(value!==''){
            let itemBarCodeList=props.item.itemBarCodeResults;
            itemBarCodeList=itemBarCodeList==undefined?[]:itemBarCodeList;
            if(validateAditionalCode(value, itemBarCodeList)){
                itemBarCodeList.push({barcode:value, alixMeasure:1});
                props.setItem(prevEedit => ({
                    ...prevEedit,
                    itemBarCodeResults:itemBarCodeList
                }));
                setShowErrors(prevErrors => ({
                    ...prevErrors,
                    aditionalCode: undefined
                }));
            }
            else{
                setShowErrors(prevErrors => ({
                    ...prevErrors,
                    aditionalCode: `El código de barras ya se encuentra registrado`
                }));
                event?.target?.focus();
            }
        }
    }

    const validateAditionalCode=(code, listCodes)=>{
        let foundCode=listCodes.find((searchedCode)=>searchedCode.barcode==code);
        if(foundCode===undefined&&code!==props.item.mainCode){
            return true;
        }
        else{
            return false;
        }
    }

    function DeleteItem(id, text) {
        let barCodeList=[];
        props.item?.itemBarCodeResults?.map((barcode)=>{
            if(barcode.barcode.toString()!==text.toString()){
                barCodeList.push(barcode);
            }
            else{
                setShowErrors();
            }
        })
        props.setItem(prevEedit => ({
            ...prevEedit,
            itemBarCodeResults:barCodeList
        }));
    }

    return(
        <div className='container-fluid container-scroll-two-bars'>
            <div className='row'>
                <div className='col-md-auto'>
                    <OmniCard title='Códigos de barras o clave'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6'>
                                <OmniTextBox title='Código principal' 
                                    maxLength={32}  
                                    value={props.item.mainCode} 
                                    edit={props.setItem} 
                                    property="mainCode" />
                            </div>
                            <div className='col-lg-6 col-md-6'>
                                <OmniSwitch
                                    start
                                    label={"Caja"}
                                    edit={props.setItem}
                                    propertyId="mainCodeUnit"
                                    checked={props.item?.mainCodeUnit}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-lg-6 col-md-6'>
                                <OmniTextBox title='Código adicional' 
                                    maxLength={32}  
                                    value={aditionalCode} 
                                    blur={onBlurAditionalCode} 
                                    validationMessage={showErrors.aditionalCode}/>
                            </div>
                            <div className='col-lg-6 col-md-6'>
                                <OmniSwitch
                                        start
                                        label={"Pieza"}
                                        edit={setAditionalMeasure}
                                        propertyId="aditionalMeasure"
                                        checked={aditionalMeasure}
                                    />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <Table>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHeaderCell className="header-cell-itemByBranch">
                                                Lista de códigos adicionales
                                            </TableHeaderCell>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                    {
                                        props.item.itemBarCodeResults?.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell >
                                                    <div className="row">
                                                        <div className="col-md-10 col-lg-10">
                                                            {item.barcode}
                                                        </div>
                                                        <div className="col-md-1 col-lg-1">
                                                            {
                                                                item.alixMeasure==0
                                                                ?<></>
                                                                :(
                                                                    item.alixMeasure==AlixMeasure.Piece
                                                                    ?<PieceIcon />
                                                                    :<BoxIcon />
                                                                )
                                                            }
                                                        </div>
                                                        <div className="col-md-1 col-lg-1">
                                                            <Tooltip
                                                                withArrow
                                                                positioning="after"
                                                                content={{ children: "Eliminar", className: stylesTooltip.tooltip }}>
                                                                <Button icon={<Image src={trashIcon} alt="delete" />} onClick={() => DeleteItem(item.index, item.barcode)} />
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    </OmniCard>
                </div>
                <div className='col-md-auto'>
                    <div className="row">
                        <OmniCard title='Número de parte'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6'>
                                    <OmniTextBox title='Número de parte' maxLength={20}  value={props.item.partNumber} edit={props.partNumber} property="partNumber" />
                                </div>
                            </div>
                        </OmniCard>
                    </div>
                    <div className="row">
                        <OmniCard title='Código del proveedor'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6'>
                                    <OmniTextBox title='Código del proveedor' maxLength={20}  value={props.item.commission} edit={props.supplierCode} property="supplierCode" />
                                </div>
                            </div>
                        </OmniCard>
                    </div>
                </div>
            </div>
        </div> 
    )
}