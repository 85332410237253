
import "../../../Assets/Styles/Shared/Table/DetailTable.css"
import {
    Table, TableBody, TableCell,
    TableCellLayout, TableHeader,
    TableHeaderCell, TableRow,
} from '@fluentui/react-components';
import { ReactComponent as WarningIcon } from '../../../Assets/Images/Icons/WarningWithoutFill.svg';
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
export const ListPriceTable = (props) => {
    const columnsHeader = [
        { columnKey: "1", label: 'NP', width: '16%' },
        { columnKey: "2", label: '$ Precio neto', width: '16%' },
        { columnKey: "3", label: '$ Utilidad', width: '16%' },
        { columnKey: "4", label: '% Descuento', width: '16%' },
        { columnKey: "5", label: '$ Final', width: '16%' },
        { columnKey: "6", label: '$ Utilidad final', width: '20%' },
    ];
    const onChangeDiscount = (event, data) => {
        const updatedPriceList = props.items.map((item) => {
            if (item.pricesCount.toString() === event.target.name.toString()) {
                let finalPriceWithTaxes = 0;
                let priceWithTaxes = parseFloat(item.priceWithTaxes)
                if (event.target.value) {
                    finalPriceWithTaxes = priceWithTaxes - (parseFloat(event.target.value.replace("%", "")) * priceWithTaxes / 100)
                }
                return {
                    ...item,
                    discount: event.target.value,
                    finalPriceWithTaxes: finalPriceWithTaxes,
                    finalProfit: (finalPriceWithTaxes - item.packagePieceCostWithTaxes).toFixed(2)
                };
            }
            return item
        })
        props.setItems((prev) => ({
            ...prev,
            priceList: prev.priceList.map((item) => {
                if (item.selected === true) {
                    return {
                        ...item,
                        listForPrice: updatedPriceList,
                        negative: updatedPriceList.filter(x => parseFloat(x.finalProfit) <= 0 && x.hasPrice === true).length
                    };
                }
                return item
            }),
        }))
    }
    const onChangeFinalPriceWithTaxes = (event, data) => {
        const updatedPriceList = props.items.map((item) => {
            if (item.pricesCount.toString() === event.target.name.toString()) {
                let discount = 0;
                if (event.target.value) {
                    discount = 100 - (parseFloat(event.target.value) * 100 / parseFloat(item.priceWithTaxes))
                }
                return {
                    ...item,
                    discount: discount,
                    finalPriceWithTaxes: event.target.value,
                    finalProfit: (event.target.value - item.packagePieceCostWithTaxes).toFixed(2)
                };
            }
            return item
        })
        props.setItems((prev) => ({
            ...prev,
            priceList: prev.priceList.map((item) => {
                if (item.selected === true) {
                    return {
                        ...item,
                        listForPrice: updatedPriceList,
                        negative: updatedPriceList.filter(x => parseFloat(x.finalProfit) <= 0 && x.hasPrice === true).length
                    };
                }
                return item
            }),
        }))
    }
    return (
        <Table className="detail-table">
            <TableHeader>
                <TableRow>
                    {columnsHeader.map((column) => (
                        <TableHeaderCell key={column.columnKey}
                            style={{ width: column.width }}
                            className={'detail-table-header-title ' + column?.classAdd}>
                            {column.label}
                        </TableHeaderCell>
                    ))}
                </TableRow>
            </TableHeader>
            <TableBody>
                {
                    props.items?.map((item) => (
                        <TableRow key={item.pricesCount} className={item.hasPrice === true ? "" : "disabled-row"}>
                            <TableCell >
                                <TableCellLayout className={"justify-content-center"} >
                                    {item.pricesCount}
                                </TableCellLayout>
                            </TableCell>
                            <TableCell >
                                <TableCellLayout className={"justify-content-center"}>
                                    {item.priceWithTaxes}
                                </TableCellLayout>
                            </TableCell>
                            <TableCell >
                                <TableCellLayout className={"justify-content-center"}>
                                    {item.profit}
                                </TableCellLayout>
                            </TableCell>
                            <TableCell >
                                {props.discountType === "1" ?
                                    <TableCellLayout className={"justify-content-center color-discount"}>
                                        {item.hasPrice === true ? `${item.discount}%` : ''}
                                    </TableCellLayout>
                                    :
                                    <TableCellLayout className={"justify-content-center"}>
                                        {item.hasPrice === true && <OmniTextBox title="" suffix="%" inputType={'decimal'}
                                            decimals={2} withoutfiel classInput="textbox-discount-table" value={item.discount}
                                            name={item.pricesCount} onChange={onChangeDiscount} max={99.99} />}
                                    </TableCellLayout>
                                }

                            </TableCell>
                            <TableCell >
                                {props.discountType === "1" ?
                                    <TableCellLayout className={`justify-content-center ${parseFloat(item.finalProfit) > 0 ? 'color-info' : 'color-danger'}`}>
                                        {item.hasPrice === true ? item.finalPriceWithTaxes : ''}
                                    </TableCellLayout>
                                    :
                                    <TableCellLayout className={"justify-content-center"}>
                                        {item.hasPrice === true && <OmniTextBox title="" inputType={'decimal'}
                                            decimals={2} withoutfiel classInput="textbox-discount-table" value={item.finalPriceWithTaxes}
                                            name={item.pricesCount} onChange={onChangeFinalPriceWithTaxes} max={parseFloat(item.priceWithTaxes)} />}
                                    </TableCellLayout>
                                }
                            </TableCell>
                            <TableCell >
                                <TableCellLayout media={item.hasPrice === true ? parseFloat(item.finalProfit) > 0 ? '' : <WarningIcon /> : ''} className={`justify-content-center ${parseFloat(item.finalProfit) > 0 ? 'color-info' : 'icon-warning'}`}>
                                    {item.hasPrice === true ? item.finalProfit : ''}
                                </TableCellLayout>
                            </TableCell>
                        </TableRow>
                    ))}
            </TableBody>
        </Table>);

}