
import "../../../../Assets/Styles/Shared/Table/DetailTable.css"
import {
    Table, TableBody, TableCell,
    TableCellLayout, TableHeader,
    TableHeaderCell, TableRow, makeStyles
} from '@fluentui/react-components';
import { ReactComponent as TaxIcon } from '../../../../Assets/Images/Icons/Ieps.svg';
const stylesTooltipAll = makeStyles({
    tooltip: {
        backgroundColor: 'var(--primary)',
        color: 'white',
    }
});
export const ItemsTablePriceList = (props) => {
    const stylesTooltip = stylesTooltipAll();
    const changeComponentClik = (id) => {
        if (props.items) {
            props.setItems((prev) => {
                const updated = props.items.map((item) => {
                    if (item.id === id) {
                        return {
                            ...item,
                            selected: true
                        };
                    }
                    return {
                        ...item,
                        selected: false
                    };
                });
                return {
                    ...prev,
                    [props.property]: updated
                };
            });
        }
    };
    return (
        <Table className="detail-table">
            <TableHeader>
                <TableRow>
                    <TableHeaderCell key={1}
                        style={{ width: '85%' }}
                        className={'detail-table-header-title first-item-delete'}
                    >
                        {props.titleheader}
                    </TableHeaderCell>
                    <TableHeaderCell key={2}
                        style={{ width: '15%' }}
                        className={'detail-table-header-title'}
                    >
                    </TableHeaderCell>
                </TableRow>
            </TableHeader>
            <TableBody>
                {
                    props.items?.map((item) => (
                        <TableRow key={item.id} className={(item.selected) ? "item-table-selected-offer" : "item-table-offer"} onClick={() => changeComponentClik(item.id)} >
                            <TableCell >
                                <TableCellLayout>
                                    {item.name.toUpperCase()}
                                </TableCellLayout>
                            </TableCell>
                            <TableCell onClick={() => changeComponentClik(item.id)}>
                                <TableCellLayout media={<TaxIcon />} className="icon-tax"> {item.rate}
                                </TableCellLayout>
                            </TableCell>
                        </TableRow>
                    ))}
            </TableBody>
        </Table>);

}

