
import "../../../Assets/Styles/Shared/Table/DetailTable.css"
import {
    Table, TableBody, TableCell,
    TableCellLayout, TableHeader,
    TableHeaderCell, TableRow,
} from '@fluentui/react-components';
export const RecordTable = (props) => {
    const columnsHeader = [
        { columnKey: "1", label: props.titleheader, width: '100%', classAdd: 'first-item-delete' },
    ];
    return (
        <Table className="detail-table">
            <TableHeader>
                <TableRow>
                    {columnsHeader.map((column) => (
                        <TableHeaderCell key={column.columnKey}
                            style={{ width: column.width }}
                            className={'detail-table-header-title ' + column?.classAdd}>
                            {column.label}
                        </TableHeaderCell>
                    ))}
                </TableRow>
            </TableHeader>
            <TableBody>
                {
                    props.items?.map((item) => (
                        <TableRow key={item.id}>
                            <TableCell >
                                <TableCellLayout>
                                    {item.name}
                                </TableCellLayout>
                            </TableCell>
                        </TableRow>
                    ))}
            </TableBody>
        </Table>);

}
