
import { ReactComponent as Offer } from '../../../../Images/Icons/Offer.svg';
export const leftButtons = () => [{
    key: 'LblTitle',
    text: <div className='row'>
        <div className='col-lg-12 col-md-12'>
            <Offer className="color-info-fill" />
            <span className="home-header-text-button">
                Ofertas por descuento
            </span>
        </div>
    </div>,
    fontSize: '16px',
    isText: true
}];
export const rightButtons = (handleClickNew, handleClickIdentifier) => [
    {
        key: 'BtnNew',
        text: 'Nueva oferta',
        iconName: 'addcuvitek',
        iconColor: '#fff',
        iconHover: '#fff',
        className: 'BtnTrigger',
        handler: handleClickNew,
        authorizationKey: 9999,
    },
{
    key: 'BtnIdentifier',
    text: 'Identificador' ,
    iconName: 'identifierCuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--primary)',
    handler: handleClickIdentifier
}
]
export const leftButtonsSecond = (description, offerType) => [{
    key: 'LblTitle',
    text: <div className='row'>
        <div className='col-lg-12 col-md-12'>
            <span className={
                offerType === 2 ? "lblTitleVariety" :
                    offerType === 3 ? "lblTitleCategory" :
                        "lblTitleItem" }>
                {description}
            </span>
        </div>
    </div>,
    fontSize: '16px'
}];
export const rightButtonsSecond = (data) => {
    const buttons = [
        {
            key: 'LblSerie',
            text: `Serie: ${data.serie} Folio:${data.folio}`,
            iconName: 'identifierCuvitek',
            iconColor: 'var(--secondary)',
            iconHover: 'var(--secondary)'
        },
        {
            key: 'LblDate',
            text: `${toShortDate(data.createDate)}`,
            iconName: 'CalendarSelectDayWithoutFillCuvitek',
            iconColor: 'var(--secondary)',
            iconHover: 'var(--secondary)'
        },
        {
            key: 'LblUser',
            text: `${data.createUserFullName}`,
            iconName: 'employeeWithoutFillCuvitek',
            iconColor: 'var(--success)',
            iconHover: 'var(--success)'
        },

    ]
    if (data.active===false) {
        buttons.push({
            key: 'LblDateInactive',
            text: `${toShortDate(data.lastUpdateDate)}`,
            iconName: 'CalendarSelectDayWithoutFillCuvitek',
            iconColor: 'var(--danger)',
            iconHover: 'var(--danger)'
        }, {
            key: 'LblUserInactive',
            text: `${data.updateUserFullName}`,
            iconName: 'employeeWithoutFillCuvitek',
            iconColor: 'var(--danger)',
            iconHover: 'var(--danger)'
        }, {
            key: 'LblUserInactive',
            text: `Oferta cancelada`,
            iconName: 'cancellationCuvitek',
            iconColor: 'var(--danger)',
            iconHover: 'var(--danger)'
        });
    }
    return buttons;
};
export const rightButtonsSecondAdd = (data) => {
    const buttons = [
        {
            key: 'LblSerie',
            text: `Serie: ${data.serie} Folio:${data.folio}`,
            iconName: 'identifierCuvitek',
            iconColor: 'var(--info)',
            iconHover: 'var(--info)'
        },
        {
            key: 'LblUser',
            text: `${data.createUserFullName}`,
            iconName: 'employeeWithoutFillCuvitek',
            iconColor: 'var(--success)',
            iconHover: 'var(--success)'
        },

    ]
    return buttons;
};
function toShortDate(dateParam) {
    if (dateParam) {
        const date = new Date(dateParam);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const dayFormat = day < 10 ? '0' + day : day;
        const monthFormat = month < 10 ? '0' + month : month;
        const dateFormateada = `${dayFormat}/${monthFormat}/${year}`;
        return dateFormateada;
    }
    return "";
}