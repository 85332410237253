import '../../../Assets/Styles/Shared/Table/DetailTable.css';
import { Table, TableBody, TableCell, TableCellLayout, TableHeader, TableHeaderCell, TableRow } from '@fluentui/react-components';
import { ItemStatusDescripcion, ItemStatus } from '../../../Assets/Constants/Enums/ItemStatus';

export const ItemsTable = (props) => {
    return (
        <>
            <Table className="detail-table">
                <TableHeader>
                    <TableRow>
                        {props.titleheaderCount === undefined ?
                            <TableHeaderCell className='detail-table-header-title-left' style={{ width: props.withoutestatus === undefined ? '70%' : '100%' }}>
                                Artículos en {props.titleheader}
                            </TableHeaderCell>
                            :
                            <TableHeaderCell className='detail-table-header-title-left' style={{ width: props.withoutestatus === undefined ? '70%' : '100%' }}>
                                {props.titleheaderCount}
                            </TableHeaderCell>
                        }
                       
                        {props.withoutestatus === undefined &&
                            <TableHeaderCell className='detail-table-header-title'>
                                Estatus
                            </TableHeaderCell>
                        }
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {
                        props.items?.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell >
                                    <TableCellLayout className={item.status === ItemStatus.Discontinued ? 'color-green' : item.status === ItemStatus.Desactive ? 'color-danger' : ''}>
                                        {item.description}
                                    </TableCellLayout>
                                </TableCell>
                                {props.withoutestatus === undefined &&
                                    < TableCell className='detail-table-cell'>
                                        <TableCellLayout className={item.status === ItemStatus.Discontinued ? 'color-green' : item.status === ItemStatus.Desactive ? 'color-danger' : ''} >
                                            {ItemStatusDescripcion.find(status => status.status === item.status)?.initial}
                                        </TableCellLayout>
                                    </TableCell>
                                }
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </>);
    
 }

