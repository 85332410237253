import Add from "../../../../../Components/Configuration/CustomersZones/Add";
import Edit from "../../../../../Components/Configuration/CustomersZones/Edit";
import authorizations from "../../../Authorizations/Configurations/CustomerZone";

export const leftButtons = [
    {
        key: 'BtnEdit',
        text: 'Editar',
        iconName: 'editcuvitek',
        iconColor: 'var(--secondary)',
        iconHover: 'var(--primary)',
        component: <Edit />,
        authorizationKey: authorizations.Edit,
    },
    {
        key: 'BtnStatus',
        text: 'Inactivar',
        iconName: 'inactivecuvitek',
        iconColor: 'var(--secondary)',
        iconHover: 'var(--primary)',
        backgroundColorButton: '',
        backgroundColorButtonHover: '',
        authorizationKey: authorizations.Delete,
    }];
export const rightButtons = [{
    key: 'BtnNew',
    text: 'Nuevo',
    iconName: 'addcuvitek',
    fontColor: '#FFFFFF',
    iconColor: '#fff',
    iconHover: '#fff',
    backgroundColorButton: 'var(--primary)',
    backgroundColorButtonHover: 'var(--primary)',
    isButton: true,
    component: <Add />,
    authorizationKey: authorizations.New,
}]