
import "../../../../Assets/Styles/Shared/Table/DetailTable.css"
import {
    Table, TableBody, TableCell,
    TableCellLayout, TableHeader,
    TableHeaderCell, TableRow, Tooltip, Button, Image, makeStyles
} from '@fluentui/react-components';
const trashIcon = require('../../../../Assets/Images/Icons/Trash.svg').default;
const stylesTooltipAll = makeStyles({
    tooltip: {
        backgroundColor: 'var(--primary)',
        color: 'white',
    }
});
export const ItemsTableCategory = (props) => {
    const stylesTooltip = stylesTooltipAll();
    const columnsHeader= [
        { columnKey: "1", label: props.titleheader, width: '85%', classAdd: 'first-item-delete' },
        { columnKey: "2", label: "", width: '15%' },
    ];
    function DeleteItem(id) {
        const filteredList = props.items.filter(x => x.id !== id);
        if (filteredList.length !== 0) {
            props.setItems(prev => ({ ...prev, [props.property]: filteredList }));
        }
    }
    return (
            <Table className="detail-table">
                <TableHeader>
                <TableRow>
                    {columnsHeader.map((column) => (
                        <TableHeaderCell key={column.columnKey}
                            style={{ width: column.width }}
                            className={'detail-table-header-title ' + column?.classAdd}>
                            {column.label}
                        </TableHeaderCell>
                    ))}
                </TableRow>
                </TableHeader>
                <TableBody>
                    {
                        props.items?.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell >
                                    <TableCellLayout>
                                        {item.name}
                                    </TableCellLayout>
                                </TableCell>
                                <TableCell>
                                    <TableCellLayout >
                                        <Tooltip
                                            withArrow
                                            positioning="after"
                                            content={{ children: "Eliminar", className: stylesTooltip.tooltip }}>
                                            <Button icon={<Image src={trashIcon} alt="delete" />} onClick={() => DeleteItem(item.id)} />
                                        </Tooltip>
                                    </TableCellLayout>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>);

}

