
import { Label } from "@fluentui/react-components";
const warehouseEndpoint = {
    controller: "warehouse",
    actions: {
        getByActive: "GetByActive",
    }
}
const documentEndpoint = {
    controller: "accountingdocument",
    actions: {
        getByFilter: "GetByFilter",
        getAccountingPoliciesByFilterMicrosip: "GetAccountingPoliciesByFilterMicrosip",
        getAccountingPoliciesByFilterContpaqi: "GetAccountingPoliciesByFilterContpaqi",
    } 
}
var axiosPrivate;
export const SetAxiosPrivate = (axiosPrivateParam) => {
    axiosPrivate = axiosPrivateParam;
}

export const GetWarehouseByActive = async () => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getUrl(warehouseEndpoint.controller, warehouseEndpoint.actions.getByActive).url);
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const GetByFilterAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.post(getUrl(documentEndpoint.controller, documentEndpoint.actions.getByFilter).url,
            JSON.stringify({
                ...data,
                updateUserName: localStorage.getItem("userName")
            }));
        if (response.data.isSuccessful) {
            if (response.data.data && response.data.data.length > 0) {
                result.data = response.data.data.map((x, indice) => ({
                    ...x,
                    date: toShortDate(x.date),
                    id: indice + 1,
                    amount: FormatMoney(x.amount),
                    ivaAmount: FormatMoney(x.ivaAmount),
                    iepsAmount: FormatMoney(x.iepsAmount),
                    netAmount: FormatMoney(x.netAmount)
                }))
            }
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const GetAccountingPoliciesByFilterMicrosipAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const accountConfigurations = localStorage.getItem("AccountConfigurations");
        const response = await axiosPrivate.post(getUrl(documentEndpoint.controller, documentEndpoint.actions.getAccountingPoliciesByFilterMicrosip).url,
            JSON.stringify({
                startDate: data.startDate,
                endDate: data.endDate,
                warehouses: data.warehouses,
                documents: data.documents,
                userName: localStorage.getItem("userName"),
                accountParam: accountConfigurations
            }),
            {
                responseType: 'blob'
            }
        );

        if (response.status === 200) {
            result.data = response.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const GetAccountingPoliciesByFilterContpaqiAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const accountConfigurations = localStorage.getItem("AccountConfigurations");
        const response = await axiosPrivate.post(getUrl(documentEndpoint.controller, documentEndpoint.actions.getAccountingPoliciesByFilterContpaqi).url,
            JSON.stringify({
                startDate: data.startDate,
                endDate: data.endDate,
                warehouses: data.warehouses,
                documents: data.documents,
                userName: localStorage.getItem("userName"),
                accountParam: accountConfigurations
            }),
            {
            responseType: 'blob'
            }
        );
        if (response.status === 200) {
            result.data = response.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const FormatMoney = (data) => {
    return Intl.NumberFormat('es-MX', {
        style: 'currency', currency: 'MXN',
        maximumFractionDigits: 2
    }).format(data)
     ;
}
 function toShortDate(dateParam) {
    if (dateParam) {
        const date = new Date(dateParam);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const dayFormat = day < 10 ? '0' + day : day;
        const monthFormat = month < 10 ? '0' + month : month;
        const dateFormateada = `${dayFormat}/${monthFormat}/${year}`;
        return dateFormateada;
    }
    return "";
}
export function ToShortDateNow() {
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const dayFormat = day < 10 ? '0' + day : day;
    const monthFormat = month < 10 ? '0' + month : month;
    const dateFormateada = `${dayFormat}${monthFormat}${year}`;
    return dateFormateada;
}
export function ToShortDateHourNow() {
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const dayFormat = day < 10 ? '0' + day : day;
    const monthFormat = month < 10 ? '0' + month : month;
    const hoursFormat = hours < 10 ? '0' + hours : hours;
    const minutesFormat = minutes < 10 ? '0' + minutes : minutes;
    const dateTimeFormateada = `${dayFormat}${monthFormat}${year} ${hoursFormat}${minutesFormat}`;

    return dateTimeFormateada;
}
export const GetDate = () => {
    let day = new Date();
    let dayMonday = new Date();
    if (day.getDay() === 1) {
        return { startDate: new Date(day.setDate(day.getDate() - 2)), endDate: new Date(dayMonday.setDate(dayMonday.getDate() - 1)), dayDiff: '2 días' };
    }
    else {
        return { startDate: day, endDate: day, dayDiff: '1 día' };

    }
}
export function DateDefault(date) {
    return <span className='cursor-pointer'> <Label>Desde el</Label> <Label className="color-info">{toShortDate(date.startDate)}</Label> <Label>hasta el</Label> <Label className="color-info">{toShortDate(date.endDate)}</Label></span>
}
export function DateDefaultDisabled(date) {
    return <span className='cursor-not-allowed'> <Label>Desde el</Label> <Label className="color-secondary">{toShortDate(date.startDate)}</Label> <Label>hasta el</Label> <Label className="color-secondary">{toShortDate(date.endDate)}</Label></span>
}

/*              PRIVATE FUNC            */
function getUrl(controller, action) {
    var result = {
        isSuccessful: false,
        url: "",
        errorMessage: ""
    }
    if (controller && action) {
        result.url = ("/" + controller + "/" + action).toLowerCase();
    } else {
        result.errorMessage = "Controller or action undefined.";
    }

    return result;
}

const getErrorText = (err) => {
    if (!err?.response) {
        return "No server response";
    } else if (err.response?.status === 400) {
        return err.response?.data?.errorMessage ?? err.response?.data.title
    }
    else if (err.response?.status === 500) {
        return err.response?.data?.detail ?? (err.message + "  (" + err.code + ")");
    }
}