import {
    leftButtonsSecond
} from '../../../../Assets/Constants/BarButtons/Offers/DiscountOffers/DiscountOffer';
import { useState, useEffect } from 'react';
import { OmniCard } from "../../../Shared/Card/OmniCard";
import { OmniRadioGroup } from '../../../Shared/Controls/Radio/OmniRadioGroup';
import { OmniCombobox } from "../../../Shared/Controls/ComboBox/OmniCombobox";
import useAxiosPrivate from '../../../../Hooks/Common/useAxiosPrivate'
import { GetItemsSuppliers, SetAxiosPrivate } from '../../.././../Functions/Catalogs/Items/ItemStore';
import { SetAxiosPrivate as SetAxiosPrivateCampaign, GetByActiveRangeDateAsync } from '../../.././../Functions/Offers/OfferCampaigns/OfferCampaignStore';
import { GetActives as GetActivesVariety, SetAxiosPrivate as SetAxiosPrivateVariety, GetItemsOfferByVarietyAsync } from '../../.././../Functions/Catalogs/Varieties/VarietyStore';
import { SetAxiosPrivate as SetAxiosPrivateBranchGroup, GetByActiveAsync } from '../../.././../Functions/Configuration/BranchGroups/BranchGroupStore';
import { SetAxiosPrivate as SetAxiosPrivateList, PriceListOfferByIdAsync } from '../../../../Functions/Catalogs/PriceLists/PriceListStore';
import { CalculateDaysBetweenDates, FormatDate } from '../../.././../Functions/Offers/DiscountOffers/DiscountOfferStore';
import { ItemsTable } from '../../../Shared/ItemsTable/ItemsTable';
import { OmniDatePicker } from '../../../Shared/Controls//DatePicker/OmniDatePicker';
import { OmniLabel } from '../../../Shared/Controls/Label/OmniLabel';
import { Button, Label } from "@fluentui/react-components";
import { ReactComponent as CategoriesIcon } from "../../../../Assets/Images/Icons/Categories.svg";
import { OverlayDrawerCategory } from './OverlayDrawerCategory';
import { OverlayDrawerBranch } from './OverlayDrawerBranch';
import { ItemsTableCategory } from './ItemsTableCategory';
import { RecordTable } from '../RecordTable';

export const Information = (props) => {
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);
    SetAxiosPrivateVariety(axiosPrivate);
    SetAxiosPrivateCampaign(axiosPrivate);
    SetAxiosPrivateBranchGroup(axiosPrivate);
    SetAxiosPrivateList(axiosPrivate);
    const [showSpinner, setShowSpinner] = useState(false);
    const [serch, setSerch] = useState();
    const [itemList, setItemList] = useState();
    const [itemListFilter, setItemListFilter] = useState();
    const [varietyList, setVarietyList] = useState();
    const [campaignListFilter, setCampaignListFilter] = useState();
    const [varietyItems, setVarietyItems] = useState();
    const [showAttachCategoryPanel, setShowAttachCategoryPanel] = useState(false);
    const [showAttachBranchGroupPanel, setShowAttachBranchGroupPanel] = useState(false);
    const [branchGroupList, setBranchGroupList] = useState();
    const itemsRadio = [
        {
            key: '1',
            value: '1',
            text: "Un artículo",
        class: 'primary-radio'
        },
        {
            key: '2',
            value: '2',
            text: "Una variedad",
            class:'red-radio'
        },
        {
            key: '3',
            value: '3',
            text: "Categorías",
        class: 'purple-radio'
        }];
    const itemsRadioBranchType = [
        {
            key: '1',
            value: '1',
            text: "Por grupo de sucursales",
        },
        {
            key: '2',
            value: '2',
            text: "Por sucursales",
        }];
    const itemsRadioBranchGroupType = [
        {
            key: '1',
            value: '1',
            text: "Todos",
        },
        {
            key: '2',
            value: '2',
            text: "Los seleccionados",
        }]
    const itemsRadioBranchesType = [
        {
            key: '1',
            value: '1',
            text: "Todas",
        },
        {
            key: '2',
            value: '2',
            text: "Las seleccionadas",
        }]
    const onCleanBranchGroup = () => {
        setBranchGroupList(prevEdit => prevEdit.map((BranchGroup) => ({
            ...BranchGroup,
            selected: false,
            filter: undefined,
            listBranchOfferResult: BranchGroup.listBranchOfferResult.map((itemBranch) => {
                return {
                    ...itemBranch,
                    selected: false,
                    filter: undefined,
                };
            })
        })))
    };
    const onChangeBranchType = (event, data) => {
        props.setDiscountOffer(prevEdit => ({ ...prevEdit, branchType: data.value, branchGroupType: '1', branchesType: '1', branchGroup: undefined }));
        onCleanBranchGroup();
    };
    const onChangeBranchGroupType = (event, data) => {
        props.setDiscountOffer(prevEdit => ({ ...prevEdit, branchGroupType: data.value, branchGroup: undefined }));
        if (data.value === '2') {
            setShowAttachBranchGroupPanel(true);
        }
        onCleanBranchGroup();
    };
    const onChangeBranchesType = (event, data) => {
        if (data.value === '2') {
            setShowAttachBranchGroupPanel(true);
        }
        props.setDiscountOffer(prevEdit => ({ ...prevEdit, branchesType: data.value, branchGroup: undefined }));
        onCleanBranchGroup();
    };
    function LoadList(text) {
        if (text) {
            setShowSpinner(false);
            const words = text.toLowerCase().split(' ');
            let filteredData = itemList.slice();
            for (const word of words) {
                if (word) {
                    filteredData = filteredData.filter(item =>
                        item.description.toLowerCase().includes(word)
                    );
                }
            }
            if (filteredData.length > 0) {
                const resultList = filteredData.map((item) => ({
                    key: item.id,
                    value: item.id,
                    text: item.description
                })).slice(0, 100);;
                setItemListFilter(resultList);
            }
        }
    }
    const onChangeOfferType = (event, data) => {
        if (data.value === '3') {
            props.setDiscountOffer(prevEdit => ({ ...prevEdit, categoryType: '1', category: undefined }));
        }
        else if (data.value === '2') {
            OnLoadGetVariety();
            props.setDiscountOffer(prevEdit => ({ ...prevEdit, varietyId: undefined, varietyDescription: undefined }));
            setVarietyItems(undefined);
        }
        props.setButtonsSecond(prevEdit =>
        ({
            ...prevEdit,
            leftButtons: leftButtonsSecond('', 1)
        }));
        props.setDiscountOffer(prevEdit => ({ ...prevEdit, offerType: data.value }));
    };
    const OnChangeText = async (text) => {
        setShowSpinner(true);
        if (itemList) {
            LoadList(text)
        }
        else {
            setSerch(text);
            setShowSpinner(true);
        }
    }
    const onChangeItem = async (id, description) => {
        props.setButtonsSecond(prevEdit =>
        ({
            ...prevEdit,
            leftButtons: leftButtonsSecond(description, 1)
        }));
        const listSelected = props.discountOffer.priceListId.map(async x => {
            const getPriceListParam = {
                itemSku: id,
                priceListId: x,
                discount: props.discountOffer.discount ? props.discountOffer.discount.replace("%", "") : 0
            }
            let result = await PriceListOfferByIdAsync(getPriceListParam)
            if (result.isSuccessful) {
                if (result.data.listPriceOfferResults && result.data.listPriceOfferResults.length > 0) {
                    return { id: x, name: result.data.name, rate: result.data.rate, listForPrice: result.data.listPriceOfferResults, negative: result.data.listPriceOfferResults.filter(y => parseFloat(y.finalProfit) <= 0 && y.hasPrice === true).length }
                }
                else {
                    return { id: x, name: result.data.name, rate: result.data.rate, listForPrice: undefined, negative: 0 }
                }
            }
        }).filter(item => item !== undefined);

        const updatedPriceList = await Promise.all(listSelected);
        if (updatedPriceList) {
            updatedPriceList[0].selected = true;
        }

        props.setDiscountOffer((prev) => ({
            ...prev,
            priceList: updatedPriceList ?? undefined,
            itemId: id, itemDescription: description 
        }))
    }
    const OnLoadGetItems = async () => {
        let result = await GetItemsSuppliers();
        if (result.isSuccessful) {
            setItemList(result.data);
        }
    }
    const OnLoadGetCampaign = async () => {
        let result = await GetByActiveRangeDateAsync();
        if (result.isSuccessful && result.data) {
            setCampaignListFilter(result.data.map((item) => ({
                key: item.id,
                value: item.id,
                text: item.name
            })));
        }
    }
    const OnLoadGetVariety = async () => {
        let result = await GetActivesVariety();
        if (result.isSuccessful) {
            setVarietyList(result.data.map((item) => ({
                key: item.id,
                value: item.id,
                text: item.name
            })));
        }
    }
    const OnLoadGetBranchGroup = async () => {
        let result = await GetByActiveAsync();
        if (result.isSuccessful && result.data) {
            setBranchGroupList(result.data);
            const distinctElements = result.data.reduce((acc, element) => {
                if (!acc.some(accElement => accElement === element.priceListId)) {
                    acc.push(element.priceListId);
                }
                return acc;
            }, []);
            props.setDiscountOffer(prevEdit => ({ ...prevEdit, priceListId: distinctElements }));
        }
    }
    const onChangeItemVariety = async (id, description) => {
        let items = await GetItemsOfferByVarietyAsync(id, 1);
        setVarietyItems([...items.data]);
        props.setDiscountOffer(prevEdit => ({ ...prevEdit, varietyId: id, varietyDescription: description, itemVarietyId: items.data[0].itemSku }));
        props.setButtonsSecond(prevEdit =>
        ({
            ...prevEdit,
            leftButtons: leftButtonsSecond(description, 2)
        }));
    }
    const onChangeCampaign = async (id, description) => {
        props.setDiscountOffer(prevEdit => ({ ...prevEdit, campaignId: id, campaignDescription: description }));
    }
    useEffect(() => {
        OnLoadGetItems();
        OnLoadGetCampaign();
        OnLoadGetBranchGroup();
    }, [])
    useEffect(() => {
        if (props.discountOffer?.category && props.discountOffer?.offerType === '3' && props.discountOffer?.category.length > 0) {
            if (props.discountOffer?.category.length === 1) {
                props.setButtonsSecond(prevEdit =>
                ({
                    ...prevEdit,
                    leftButtons: leftButtonsSecond(props.discountOffer?.category[0].name, 3)
                }));
            }
            else {
                props.setButtonsSecond(prevEdit =>
                ({
                    ...prevEdit,
                    leftButtons: leftButtonsSecond(props.discountOffer?.category[0].name + " (" + props.discountOffer?.category.length + ")", 3)
                }));
            }
        }
    }, [props.discountOffer?.category])
    return (
        <>
            <div className='row container-information'>
                <div className='col-md-4'>
                    <OmniCard title={`Oferta ${props.discountOffer.offerType === '1' ? 'para un artículo' : props.discountOffer.offerType === '2' ? 'para una variedad de artículos' : 'por categorías'}`} classCard='card-omni-without-width'>
                        <div className='row'>
                            <div className='col-12'>
                                <OmniRadioGroup name="offerType"
                                    title="Esta oferta aplica a"
                                    orientation="horizontal"
                                    items={itemsRadio}
                                    default={props.discountOffer.offerType} onChange={onChangeOfferType} class='radio-group-omni-offer' />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12'>
                                {props.discountOffer.offerType === '1' ?
                                    <OmniCombobox title={"Artículo"}
                                        showSpinner={showSpinner}
                                        items={itemListFilter}
                                        onChangeItemText={OnChangeText}
                                        onChangeItem={onChangeItem} selected={props.discountOffer?.itemId}
                                        value={props.discountOffer?.itemDescription}
                                    />
                                    :
                                    props.discountOffer.offerType === '2' ?
                                        <>
                                            <OmniCombobox title={"Variedad"}
                                                items={varietyList}
                                                onChangeItem={onChangeItemVariety} selected={props.discountOffer?.varietyId}
                                                value={props.discountOffer?.varietyDescription}
                                            />
                                            {varietyItems &&
                                                <div id='divItemsContainer' className='row card-items-container' >
                                                    <div className='col-lg-12 col-md-12'>
                                                        <ItemsTable titleheaderCount={"Lista de artículos (" + (isNaN(varietyItems?.length) ? 0 : varietyItems?.length) + ")"} items={varietyItems} withoutestatus />
                                                    </div>
                                                </div>
                                            }
                                        </>
                                        :
                                        <>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <Label weight="semibold">Categorías</Label>
                                                </div>
                                            </div>
                                            <div className='row mt-2'>
                                                <div className='col-12'>
                                                    <Button className="btn-category" onClick={() => { setShowAttachCategoryPanel(true) }}>
                                                        <CategoriesIcon />
                                                        <span>
                                                            Seleccionar
                                                        </span>
                                                    </Button>
                                                </div>
                                            </div>
                                            {props.discountOffer?.category && props.discountOffer?.offerType === '3' &&
                                                <div id='divItemsContainer' className='row card-items-container' >
                                                    <div className='col-lg-12 col-md-12'>
                                                        <ItemsTableCategory
                                                            titleheader={"Lista de categorías seleccionadas (" + (isNaN(props.discountOffer?.category?.length) ? 0 : props.discountOffer?.category?.length) + ")"}
                                                            items={props.discountOffer?.category}
                                                            setItems={props.setDiscountOffer} property={"category"}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                        </>
                                }
                            </div>
                        </div>
                    </OmniCard>
                </div>
                <div className='col-md-4'>
                    <OmniCard title='Alcance' classCard='card-omni-without-width'>
                        <div className='row'>
                            <div className='col-5'>
                                <OmniDatePicker
                                    title='Fecha de inicio'
                                    property="startDate"
                                    className="datepicker-omni-60"
                                    edit={props.setDiscountOffer}
                                    value={props.discountOffer?.startDate}
                                    maxDate={props.discountOffer?.endDate}
                                />
                            </div>
                            <div className='col-5'>
                                <OmniDatePicker
                                    title='Fecha de fin'
                                    property="endDate"
                                    className="datepicker-omni-60"
                                    edit={props.setDiscountOffer}
                                    value={props.discountOffer?.endDate}
                                    minDate={props.discountOffer?.startDate}
                                />
                            </div>
                            <div className='col-2'>
                                <OmniLabel className='colorLabelContentBlue' text=''
                                    value={CalculateDaysBetweenDates(FormatDate(props.discountOffer?.startDate), FormatDate(props.discountOffer?.endDate))} />

                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <OmniCombobox title={"Campaña"}
                                    items={campaignListFilter}
                                    onChangeItem={onChangeCampaign} selected={props.discountOffer?.campaignId}
                                    value={props.discountOffer?.campaignDescription}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <OmniRadioGroup name="branchType"
                                    title="Grupo de sucursales"
                                    orientation="horizontal"
                                    items={itemsRadioBranchType}
                                    default={props.discountOffer.branchType} onChange={onChangeBranchType} />
                            </div>
                        </div>
                        {props.discountOffer?.branchType === "1" ?
                            <div className='row'>
                                <div className='col-12'>
                                    <OmniRadioGroup name="branchGroupType"
                                        title="Grupo de sucursales"
                                        orientation="horizontal"
                                        items={itemsRadioBranchGroupType}
                                        default={props.discountOffer.branchGroupType} onChange={onChangeBranchGroupType} />
                                </div>
                            </div>
                            :
                        <div className='row'>
                            <div className='col-12'>
                                    <OmniRadioGroup name="branchesType"
                                    title="Sucursales"
                                    orientation="horizontal"
                                        items={itemsRadioBranchesType}
                                        default={props.discountOffer.branchesType} onChange={onChangeBranchesType} />
                            </div>
                        </div>
                        }
                        {(props.discountOffer?.branchGroup && props.discountOffer?.branchGroup?.length > 0) &&
                            <div className='row card-items-container' >
                                <div className='col-lg-12 col-md-12'>
                                    <ItemsTable
                                        titleheaderCount={`Lista de ${props.discountOffer.branchType === '2' ? 'sucursales' : 'grupo de sucursales'} (${props.discountOffer?.branchGroup?.length})`}
                                        items={props.discountOffer?.branchGroup} withoutestatus
                                    />
                                </div>
                            </div>
                        }
                    </OmniCard>
                </div>
                <div className='col-md-4'>
                    <OmniCard title={`Histórico de descuentos ${props.discountOffer.offerType === '1' ? 'del artículo' : props.discountOffer.offerType === '2' ? 'de la variedad' : 'de la categoría'}`}
                        classCard='card-omni-without-width'>
                                <RecordTable
                                    titleheader={"Lista de descuentos (" + (isNaN(props.discountOffer?.category?.length) ? 0 : props.discountOffer?.category?.length) + ")"}
                                    items={props.discountOffer?.category}
                                />
                    </OmniCard>
                </div>
            </div>
            <OverlayDrawerCategory showAttachCategoryPanel={showAttachCategoryPanel}
                setShowAttachCategoryPanel={setShowAttachCategoryPanel}
                setParam={props.setDiscountOffer}
                param={props.discountOffer} setButtonsSecond={props.setButtonsSecond}
            
            />
            {branchGroupList &&
                <OverlayDrawerBranch showAttachBranchGroupPanel={showAttachBranchGroupPanel}
                    setShowAttachBranchGroupPanel={setShowAttachBranchGroupPanel}
                    setParam={props.setDiscountOffer}
                    param={props.discountOffer} branchGroupList={branchGroupList}
                    setBranchGroupList={setBranchGroupList}
                />
            }
        </>
    )
}