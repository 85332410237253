export const GeneralStatusDescription = [
    { status: 0, description: "Inactivo", initial: "I" },
    { status: 1, description: "Activo", initial: "A" },
    { status: 3, description: "Desactivado", initial: "D" }
];


export const GeneralStatus = {
    Inactive: 0,
    Active: 1,
    Deactivated: 3
}