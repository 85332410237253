export const rightButtons = (handleClickNew, handleClickIdentifier, active, handleClickCancel, handleClickDuplicate) => {
    const buttons = [
        {
            key: 'BtnNew',
            text: 'Nueva oferta',
            iconName: 'addcuvitek',
            iconColor: '#fff',
            iconHover: '#fff',
            className: 'BtnTrigger',
            handler: handleClickNew,
            authorizationKey: 9999,
        },
        {
            key: 'BtnIdentifier',
            text: 'Identificador',
            iconName: 'identifierCuvitek',
            iconColor: 'var(--secondary)',
            iconHover: 'var(--primary)',
            handler: handleClickIdentifier
        }

    ]
    if (active === true) {
        buttons.push({
            key: 'BtnCancel',
            text: 'Cancelar',
            iconName: 'cancellationCuvitek',
            iconColor: 'var(--secondary)',
            iconHover: 'var(--danger)',
            handler: handleClickCancel
        });
    }
    buttons.push(
        {
            key: 'BtnDuplicate',
            text: 'Duplicar',
            iconName: 'duplicateCuvitek',
            iconColor: 'var(--secondary)',
            iconHover: 'var(--primary)',
            handler: handleClickDuplicate
        });
    return buttons;
};