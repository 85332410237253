import { OmniCard } from "../../../Shared/Card/OmniCard";
import { ItemsTable } from '../../../Shared/ItemsTable/ItemsTable';
import { OmniLabel } from '../../../Shared/Controls/Label/OmniLabel';
import { Label } from "@fluentui/react-components";
import { RecordTable } from '../RecordTable';

export const Information = (props) => {
   
    return (
        <>
            <div className='row container-information'>
                <div className='col-md-4'>
                    <OmniCard title={`Oferta ${props.discountOffer.offerType === '1' ? 'para un artículo' : props.discountOffer.offerType === '2' ? 'para una variedad de artículos' : 'por categorías'}`} classCard='card-omni-without-width'>
                        <div className='row'>
                            <div className='col-12'>
                                <Label weight="semibold">{props.discountOffer.offerType === '1' ? 'Artículo' : props.discountOffer.offerType === '2' ? 'Variedad' : 'Categorías'}</Label>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12'>
                                <Label weight="semibold">{props.discountOffer.offerType === '1' ? props.discountOffer?.itemDescription : props.discountOffer.offerType === '2' ? props.discountOffer?.varietyDescription : 'Categoría'}</Label>
                                {(props.discountOffer?.offerType === '2' && props.discountOffer.categoryItems && props.discountOffer.categoryItems.length >0) &&
                                    <div id='divItemsContainer' className='row card-items-container' >
                                        <div className='col-lg-12 col-md-12'>
                                            <ItemsTable titleheaderCount={"Lista de artículos (" + (props.discountOffer.categoryItems?.length) + ")"} items={props.discountOffer.categoryItems} withoutestatus />
                                        </div>
                                    </div>
                                }
                                {(props.discountOffer?.offerType === '3' && props.discountOffer?.category && props.discountOffer?.category.length > 0)  &&
                                    <div id='divItemsContainer' className='row card-items-container' >
                                        <div className='col-lg-12 col-md-12'>
                                            <ItemsTable titleheaderCount={"Lista de categorías (" + (props.discountOffer.category?.length) + ")"} items={props.discountOffer.category} withoutestatus />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </OmniCard>
                </div>
                <div className='col-md-4'>
                    <OmniCard title='Alcance' classCard='card-omni-without-width'>
                        <div className='row'>
                            <div className='col-5'>
                                <OmniLabel text='Fecha de inicio' value={props.discountOffer?.startDate} />
                            </div>
                            <div className='col-5'>
                                <OmniLabel text='Fecha de fin' value={props.discountOffer?.endDate} />
                            </div>
                            <div className='col-2'>
                                <OmniLabel className='colorLabelContentBlue' text='' value={props.discountOffer?.daysBetweenDates} />

                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <OmniLabel text='Campaña' value={props.discountOffer?.campaignDescription} />
                            </div>
                        </div>
                        {(props.discountOffer?.branchGroup && props.discountOffer?.branchGroup?.length > 0) &&
                            <div className='row card-items-container' >
                                <div className='col-lg-12 col-md-12'>
                                    <ItemsTable
                                        titleheaderCount={`Lista de ${props.discountOffer.branchType === '2' ? 'sucursales' : 'grupo de sucursales'} (${props.discountOffer?.branchGroup?.length})`}
                                        items={props.discountOffer?.branchGroup} withoutestatus
                                    />
                                </div>
                            </div>
                        }
                    </OmniCard>
                </div>
                <div className='col-md-4'>
                    <OmniCard title={`Histórico de descuentos ${props.discountOffer.offerType === '1' ? 'del artículo' : props.discountOffer.offerType === '2' ? 'de la variedad' : 'de la categoría'}`}
                        classCard='card-omni-without-width'>
                        <RecordTable
                            titleheader={"Lista de descuentos (" + (isNaN(props.discountOffer?.category?.length) ? 0 : props.discountOffer?.category?.length) + ")"}
                            items={props.discountOffer?.category}
                        />
                    </OmniCard>
                </div>
            </div>
        </>
    )
}