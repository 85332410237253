import { OmniCard } from "../../../Shared/Card/OmniCard";
import { ItemsTablePriceList } from './ItemsTablePriceList';
import { ListPriceTable } from '../ListPriceTable';
import { OmniLabel } from '../../../Shared/Controls/Label/OmniLabel';
export const Discount = (props) => {
    
    return (
        <>
            <div className='row container-information'>
                <div className='col-md-4'>
                    <OmniCard title="Listas de precios" classCard='card-omni-without-width'>
                        <div className='row'>
                            <div className='col-12'>
                                <OmniLabel text='Aplicación del descuento' value={props.discountOffer?.discountType === "2" ? "Individual" : `General del ${props.discountOffer?.discount}`}/>
                            </div>
                        </div>
                        {props.discountOffer?.priceList && props.discountOffer?.priceListType.length > 0 &&
                            <div className='row card-items-container' >
                                <div className='col-lg-12 col-md-12'>
                                    <ItemsTablePriceList
                                        titleheader={"Listas de precios (" + (props.discountOffer?.priceList?.length) + ")"}
                                        items={props.discountOffer?.priceList} setItems={props.setDiscountOffer} property={"priceList"}
                                    />
                                </div>
                            </div>
                        }
                    </OmniCard>
                </div>
                <div className='col-md-8'>
                    <OmniCard title={(
                        <span>
                            Lista de precios:   <span className="title-pricelist">{props.discountOffer?.priceList?.find(x => x.selected === true)?.name || ''}</span>
                        </span>)} classCard='card-omni-without-width'>
                        {props.discountOffer?.priceList?.find(x => x.selected === true).listForPrice &&
                            <div className='row card-items-container-discount' >
                                <div className='col-lg-12 col-md-12'>
                                    <ListPriceTable items={props.discountOffer?.priceList?.find(x => x.selected === true).listForPrice} setItems={props.setDiscountOffer}
                                        discountType={props.discountOffer?.discountType} />
                                </div>
                            </div>
                        }
                    </OmniCard>
                </div>
            </div>
        </>
    )
}