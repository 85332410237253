const offerEndpoint = {
    controller: "offer",
    actions: {
        getBySerie: "GetBySerie",
        updateCancel:"UpdateCancel"
    }
}
var axiosPrivate;
export const SetAxiosPrivate = (axiosPrivateParam) => {
    axiosPrivate = axiosPrivateParam;
}

export const GetByIdSerieAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.post(getUrl(offerEndpoint.controller, offerEndpoint.actions.getBySerie).url,
            JSON.stringify({
                serie: data.serie,
                folio: data.folio
        }));
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    }
    catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const UpdateCancelAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.post(getUrl(offerEndpoint.controller, offerEndpoint.actions.updateCancel).url,
            JSON.stringify({
                serie: data.serie,
                folio: data.folio
            }));
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
            result.successMessage = `La oferta ha sido cancelada exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    }
    catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}

export const FormatDate = (date) => {
    if (date == undefined) {
        return undefined;
    }
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};
export const CalculateDaysBetweenDates = (startDate, endDate) => {
    if (startDate == undefined || endDate == undefined) {
        return '';
    }
    const parseDate = (dateString) => {
        const [day, month, year] = dateString.split("/").map(Number);
        return new Date(year, month - 1, day);
    };
    const start = parseDate(startDate);
    const end = parseDate(endDate);
    const differenceInTime = end.getTime() - start.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24) + 1;
    const roundDays = Math.ceil(differenceInDays);
    const days = roundDays == 1 ? roundDays + ' día' : roundDays + ' días';
    return days;
};
function getUrl(controller, action) {
    var result = {
        isSuccessful: false,
        url: "",
        errorMessage: ""
    }
    if (controller && action) {
        result.url = ("/" + controller + "/" + action).toLowerCase();
    } else {
        result.errorMessage = "Controller or action undefined.";
    }

    return result;
}
const getErrorText = (err) => {
    if (!err?.response) {
        return "No server response";
    } else if (err.response?.status === 400) {
        return err.response?.data?.errorMessage
    }
    else if (err.response?.status === 500) {
        return err.response?.data?.detail;
    }
}