import { Label, Tooltip, Image } from "@fluentui/react-components";
import { ReactComponent as Document } from '../../../../Images/Icons/Document.svg';
import { ReactComponent as WarehouseWithoutFill } from '../../../../Images/Icons/WarehouseWithoutFill.svg';
const indexIcon = require('../../../../Images/Icons/MasterPolicy.svg').default;
export const leftButtons = (textDate, listWarehouse, listDocument) => [
    {
        key: 'BtnDocument',
        text: <Tooltip content={listDocument === undefined || listDocument?.length === 0 ? "Todos" : <>
            {listDocument.map((documentName, index) => (
                <div key={index} className="col-12">
                    {documentName}
                </div>
            ))}
        </>} positioning="below">
            <div className='row'>
                <div className='col-lg-12 col-md-12 btn-icon-disabled'>
                    <Document />
                    <Label className='cursor-not-allowed'>Documentos</Label>
                </div>
            </div>
        </Tooltip>,
        disabled: true
    },
    {
        key: 'BtnWarehouse',
        text: <Tooltip content={listWarehouse === undefined || listWarehouse?.length === 0 ? "Todos" : <>
            {listWarehouse.map((warehouseName, index) => (
                <div key={index} className="col-12">
                    {warehouseName}
                </div>
            ))}
        </>} positioning="below">
            <div className='row'>
                <div className='col-lg-12 col-md-12 btn-icon-disabled'>
                    <WarehouseWithoutFill />
                    <Label className='cursor-not-allowed'>Almacenes</Label>
                </div>
            </div>
        </Tooltip>,
        disabled: true
    },
    {
        key: 'BtnCalendar',
        text: textDate,
        iconName: 'dateRangeCuvitek',
        iconColor: 'var(--secondary)',
        iconHover: 'var(--secondary)',
        disabled: true
    },
];
export const leftButtonsExec = (handleClickCalendar, handleClickWarehouse, handleClickDocument, textDate, listWarehouse, listDocument) => [
    {
        key: 'BtnDocument',
        text: <Tooltip content={listDocument === undefined || listDocument?.length === 0 ? "Todos" : <>
            {listDocument.map((documentName, index) => (
                <div key={index} className="col-12">
                    {documentName}
                </div>
            ))}
        </>} positioning="below">
            <div className='row'>
                <div className='col-lg-12 col-md-12 btn-icon-info'>
                    <Document className="icon-info-label" />
                    <Label className='cursor-pointer'>Documentos</Label>
                </div>
            </div>
        </Tooltip>,
        handler: handleClickDocument
    },
    {
        key: 'BtnWarehouse',
        text: <Tooltip content={listWarehouse === undefined || listWarehouse?.length === 0 ? "Todos" : <>
            {listWarehouse.map((warehouseName, index) => (
                <div key={index} className="col-12">
                    {warehouseName}
                </div>
            ))}
        </>} positioning="below">
            <div className='row'>
                <div className='col-lg-12 col-md-12 btn-icon-info'>
                    <WarehouseWithoutFill className="icon-info-label" />
                    <Label className='cursor-pointer'>Almacenes</Label>
                </div>
            </div>
        </Tooltip>,
        handler: handleClickWarehouse
    },
    {
        key: 'BtnCalendar',
        text: textDate,
        iconName: 'dateRangeCuvitek',
        iconColor: 'var(--secondary)',
        iconHover: 'var(--info)',
        handler: handleClickCalendar
    }
];
export const rightButtonsSecond = (handleClickTrigger, handleClickNewQuery, daysDocument) => [{
    key: 'BtnTrigger',
    text:'Generar Reporte',
    iconName: 'gridCuvitek',
    iconColor: daysDocument === -1 ? 'var(--secondary)' : '#fff',
    iconHover: daysDocument === -1 ? 'var(--secondary)' : '#fff',
    handler: handleClickTrigger,
    className: daysDocument === -1 ? '' : 'BtnTrigger',
    disabled: daysDocument === -1 ? true : false
}, {
        key: 'BtnExcel',
        text: 'Exportar a Excel',
        iconName: 'excelDownloadCuvitek',
        iconColor: 'var(--secondary)',
        iconHover: 'var(--secondary)',
        disabled: true
    },
    {
        key: 'BtnDownload',
        text: 'Descargar',
        iconName: 'downloadCloudCuvitek',
        iconColor: 'var(--secondary)',
        iconHover: 'var(--secondary)',
        disabled: true
    },
    {
        key: 'BtnNewQuery',
        text: 'Nueva consulta',
        iconName: 'newQueryCuvitek',
        iconColor: 'var(--info)',
        iconHover: 'var(--info)',
        handler: handleClickNewQuery
    }
]
export const rightButtonsSecondDisabled = () => [{
    key: 'BtnTrigger',
    text: 'Generar Reporte',
    iconName: 'gridCuvitek',
    className: 'BtnTriggerDisabled',
    disabled: true
}, {
    key: 'BtnExcel',
    text: 'Exportar a Excel',
    iconName: 'excelDownloadCuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--secondary)',
    disabled: true
},
{
    key: 'BtnDownload',
    text: 'Descargar',
    iconName: 'downloadCloudCuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--secondary)',
    disabled: true
},
{
    key: 'BtnNewQuery',
    text: 'Nueva consulta',
    iconName: 'newQueryCuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--secondary)',
    disabled: true
}
]
export const rightButtonsExecSecond = (handleClickExcel, handleClickDownload, handleClickNewQuery) => [{
    key: 'BtnTrigger',
    text: 'Generar reporte',
    iconName: 'gridCuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--secondary)',
    disabled: true
}, {
    key: 'BtnExcel',
    text: 'Exportar a Excel',
    iconName: 'excelDownloadCuvitek',
    iconColor: 'var(--success)',
    iconHover: 'var(--success)',
    handler: handleClickExcel
    },
{
    key: 'BtnDownload',
    text: 'Descargar',
    iconName: 'downloadCloudCuvitek',
    iconColor: 'var(--info)',
    iconHover: 'var(--info)',
    handler: handleClickDownload
    },
    {
        key: 'BtnNewQuery',
        text: 'Nueva consulta',
        iconName: 'newQueryCuvitek',
        iconColor: 'var(--info)',
        iconHover: 'var(--info)',
        handler: handleClickNewQuery
    }
]
export const leftButtonsSecond = () => [{
    key: 'LblTitle',
    text: <div className='row'>
        <div className='col-lg-12 col-md-12'>
            <Image src={indexIcon} alt="indexIcon" />
            <span className="home-header-text-button">
                Póliza maestra
            </span>
        </div>
    </div>,
    fontSize: '16px',
    isText: true
}];
