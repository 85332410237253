import '../../../../Assets/Styles/Shared/Controls/OmniLabel.css'
import '../../../../Assets/Styles/Shared/Controls/OmniRadioGroup.css'
import { OmniFieldLabel } from '../FieldLabel/OmniFieldLabel'
import { useRef, useEffect, useState } from 'react';
import { Field, RadioGroup, Radio, Tooltip } from "@fluentui/react-components";
import { useFieldChangeWatcherContext } from "../../../../Context/Controls/FieldChangeWatcherProvider"
export const OmniRadioGroup = (props) => {
    const fieldEdited = useRef(false);
    const initialFieldValue = GetInitialSelectedOption();
    const [chekedValue, setChekedValue] = useState(initialFieldValue);
    const formControlId = props.name;
    const fieldWatcher = useFieldChangeWatcherContext();
    function GetInitialSelectedOption() {
        let initialSelectedOpction;
        if (props?.default) {
            initialSelectedOpction = props?.default
        }
        else {
            initialSelectedOpction = '';
        }
        return initialSelectedOpction;
    };
    const OnChekedhancheHandler = (event, data) => {
        setChekedValue(data.value);
        if (initialFieldValue === data.value) {
            fieldEdited.current = false;
        }
        else {
            if (!props.inactivefieldEdited) {
                fieldEdited.current = true;
            }
        }
        props.onChange && props.onChange(event, data);
        if (props.propertyId && props.edit) {
            props.edit(prevEedit => ({ ...prevEedit, [props.propertyId]: data.value }));
        }
    };
    //init control on Context
    useEffect(() => {
        if (fieldWatcher) {
            if (!fieldWatcher.contextControlsRef.current.some(item => item.id === formControlId)) {
                fieldWatcher.contextControlsRef.current = [...fieldWatcher.contextControlsRef.current, { id: formControlId, edited: false }];
            }
            fieldWatcher.updateHasPendingChanges();
        }
    }, []);
    //update context
    useEffect(() => {
        if (fieldWatcher) {
            if (fieldWatcher.contextControlsRef.current && fieldWatcher.contextControlsRef.current.length > 0) {
                fieldWatcher.contextControlsRef.current = (fieldWatcher.contextControlsRef.current.map(c => {
                    if (c.id === formControlId) {
                        return { ...c, edited: fieldEdited.current }
                    }
                    return c;
                }));
                fieldWatcher.updateHasPendingChanges();
            }
        }
    }, [fieldEdited.current]);
    useEffect(() => {
        setChekedValue(props?.default);
    }, [props?.default]);
    return (
        <>
                <div className={'container-controls'} >
                    <Field className={"label-omni"}>
                        <OmniFieldLabel  {...props} formControlId={formControlId} fieldEdited={fieldEdited.current}></OmniFieldLabel>
                    <RadioGroup layout={props.orientation}
                        onChange={OnChekedhancheHandler}
                        defaultValue={chekedValue}
                        value={chekedValue }
                        {...props} title=""
                        className={props.class ?? 'radio-group-omni'}>
                        {props?.items && props.items.map((item) => (
                            <div key={item.key}> {item?.tooltip ? (
                                <Tooltip
                                    withArrow
                                    positioning="above-start"
                                    content={{ children: item.tooltip }}>
                                    <div key={item.key}>
                                        <Radio
                                            key={item.key}
                                            value={item.value}
                                            label={item.text}
                                            className={item.class}
                                            {...props.register} />
                                    </div>
                                </Tooltip>
                            ) : (<Radio
                                key={item.key}
                                value={item.value}
                                label={item.text}
                                className={item.class}
                                {...props.register} />)
                            }
                            </div>
                        ))}

                    </RadioGroup>
                    </Field>
                </div>
        </>
    );
}