import { Checkbox, CompoundButton, Table, TableBody, TableCell, TableCellLayout, TableHeader, TableHeaderCell, TableRow } from "@fluentui/react-components";
import { OmniCard } from "../../../Shared/Card/OmniCard";
import { OmniLabel } from "../../../Shared/Controls/Label/OmniLabel";
import { OmniTextBox } from "../../../Shared/Controls/TextBox/OmniTextBox";
import { OmniCheckBox } from "../../../Shared/Controls/Checkbox/OmniCheckBox";
import { OmniRadioGroup } from "../../../Shared/Controls/Radio/OmniRadioGroup";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";

const literalRadio = [
    {
        key: "CAJA12",
        value: "C",
        text: "Cajas",
    },
    {
        key: "PIEZA12",
        value: "P",
        text: "Piezas",
        class:"red-indicator"
    },
    {
        key: "PAQUETE12",
        value: "O",
        text: "Paquetes",
        class:"black-indicator"
    }
];

export default function Resctocking(props){
    const { register} = useForm();
    const[visibleAmount,setVisibleAmount]=useState(props.item?.literalRefillMeasure==="P"&&props.item?.amountToRefilled>1?true:false);
    const[defaultLiteral, setDefaultLiteral]=useState(props.item?.literalRefillMeasure==="P"&&props.item?.amountToRefilled>1?"O":props.item?.literalRefillMeasure);

    const onChangeCheckBoxRestock=(event, data)=>{
        let actual=[];
        props.item?.itemRestockResults.map((branch)=>{
            if(branch.idWarehouseStockItem.toString()==event.target.name.toString()){
                branch.restock=data.checked
            }
            actual.push(branch);
        });
        props.setItem((prev) => ({
            ...prev,
            itemRestockResults: actual,
        }));
    }
    const onChangeMinimum=(event, data)=>{
        let actual=[];
        props.item?.itemRestockResults.map((stock)=>{
            if(stock.idWarehouseStockItem.toString()==event.target.name.substring(3,event.target.name.length).toString()){
                    stock.minimum=event.target.value
            }
            actual.push(stock);
        })
        props.setItem((prev) => ({
            ...prev,
            itemRestockResults: actual,
        }));
    }
    const onBlurMinimum=(event, data)=>{
        let actual=[];
        props.item?.itemRestockResults.map((stock)=>{
            if(stock.idWarehouseStockItem.toString()==event.target.name.substring(3,event.target.name.length).toString()){
                let dataArray=event.target.value.split('/');
                if(dataArray.length<=1){
                    stock.minimum=event.target.value+"/0"
                    event.target.value=stock.minimum;
                }
                else if(dataArray.length>2){
                    stock.minimum="0/0";
                    event.target.value=stock.minimum;
                }
            }
            actual.push(stock);
        })
        props.setItem((prev) => ({
            ...prev,
            itemRestockResults: actual,
        }));
    }
    const onChangeMaximum=(event, data)=>{
        let actual=[];
        props.item?.itemRestockResults.map((stock)=>{
            if(stock.idWarehouseStockItem.toString()==event.target.name.substring(3,event.target.name.length).toString()){
                stock.maximum=event.target.value
            }
            actual.push(stock);
        })
        props.setItem((prev) => ({
            ...prev,
            itemRestockResults: actual,
        }));
    }

    const onBlurMaximum=(event, data)=>{
        let actual=[];
        props.item?.itemRestockResults.map((stock)=>{
            if(stock.idWarehouseStockItem.toString()==event.target.name.substring(3,event.target.name.length).toString()){
                let dataArray=event.target.value.split('/');
                if(dataArray.length<=1){
                    stock.maximum=event.target.value+"/0"
                    event.target.value=stock.maximum;
                }
                else if(dataArray.length>2){
                    stock.maximum="0/0";
                    event.target.value=stock.maximum;
                }
            }
            actual.push(stock);
        })
        props.setItem((prev) => ({
            ...prev,
            itemRestockResults: actual,
        }));
    }

    const onChangeCapacity=(event, data)=>{
        let actual=[];
        props.item?.itemRestockResults.map((stock)=>{
            if(stock.idWarehouseStockItem.toString()==event.target.name.substring(3,event.target.name.length).toString()){
                stock.capacity=event.target.value
            }
            actual.push(stock);
        })
        props.setItem((prev) => ({
            ...prev,
            itemRestockResults: actual,
        }));
    }

    const onBlurCapacity=(event, data)=>{
        let actual=[];
        props.item?.itemRestockResults.map((stock)=>{
            if(stock.idWarehouseStockItem.toString()==event.target.name.substring(3,event.target.name.length).toString()){
                let dataArray=event.target.value.split('/');
                if(dataArray.length<=1){
                    stock.capacity=event.target.value+"/0"
                    event.target.value=stock.capacity;
                }
                else if(dataArray.length>2){
                    stock.capacity="0/0";
                    event.target.value=stock.capacity;
                }
            }
            actual.push(stock);
        })
        props.setItem((prev) => ({
            ...prev,
            itemRestockResults: actual,
        }));
    }

    const onChangeRestock = (event,data) => {
        let literalParam = data.value.toString();
        setDefaultLiteral(literalParam);
        if(literalParam==="O"){
            setVisibleAmount(true);
            
        }
        else{
            setVisibleAmount(false);
            props.setItem((prev) => ({
                ...prev,
                amountToRefilled: 1,
            }));
        }
        props.setItem((prev) => ({
            ...prev,
            literalRefillMeasure:literalParam
        }));
    };

    const setItemRestockParams=()=>{
        let itemRestockParams=[];
        let consecutive=1;
        props.warehouses?.map((warehouse)=>{
            let branch=props.branches?.find((branch)=>branch.id==warehouse.cuvikBranchId);
            itemRestockParams.push({
                idWarehouseStockItem:warehouse.id,
                consecutiveItem:consecutive++,
                isWarehouse:warehouse.isWarehouse,
                warehouseType:warehouse.isWarehouse?"BOD":"TIE",
                warehouseName:warehouse.name,
                minimum:"0/0",
                maximum:"0/0",
                capacity:"0/0",
                restock:false,
            });
        });
        props.setItem((prev)=>({
            ...prev,
            itemRestockParams:itemRestockParams
        }))
    }

    useEffect(()=>{
        setItemRestockParams();
    },[])

    return (
        <div className='container-fluid container-scroll-two-bars'>
            <div className='row'>
                <div className='col-lg-8 col-8'>
                    <OmniCard title='Tipo de resurtido'>
                        <div className='row'>
                            <div className='col-lg-9 col-md-9'>
                                <OmniRadioGroup name="rbtnLiteralrefill"
                                    title="Este articulo se resurte en"
                                    orientation="horizontal"
                                    items={literalRadio}
                                    default={defaultLiteral}
                                    onChange={onChangeRestock}
                                    register={{ ...register("literalRefillMeasure") }} />
                            </div>
                            {
                                visibleAmount
                                ?
                                <div className='col-lg-3 col-md-3'>
                                    <OmniTextBox value={props.item?.amountToRefilled} edit={props.setItem} property="amountToRefilled" />
                                </div>
                                :<></>
                            }
                            
                        </div>
                    </OmniCard>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-12 col-12'>
                    <OmniCard title='Existencias por almacén'  classCard="long-card">
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                <Table>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHeaderCell className="header-cell-itemByBranch">
                                                ID
                                            </TableHeaderCell>
                                            <TableHeaderCell className="header-cell-itemByBranch">
                                                Tipo
                                            </TableHeaderCell>
                                            <TableHeaderCell className="header-cell-itemByBranch">
                                                Nombre del almácen
                                            </TableHeaderCell>
                                            <TableHeaderCell className="header-cell-itemByBranch">
                                                Minimos
                                            </TableHeaderCell>
                                            <TableHeaderCell className="header-cell-itemByBranch">
                                                Máximos
                                            </TableHeaderCell>
                                            <TableHeaderCell className="header-cell-itemByBranch">
                                                Capacidad
                                            </TableHeaderCell>
                                            <TableHeaderCell className="header-cell-itemByBranch">
                                                Resurtir
                                            </TableHeaderCell>
                                            <TableHeaderCell className="header-cell-itemByBranch">
                                                Medida
                                            </TableHeaderCell>
                                            <TableHeaderCell className="header-cell-itemByBranch">
                                                Piezas
                                            </TableHeaderCell>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                    {
                                        props.item?.itemRestockParams?.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <TableCellLayout className="cell-bold">
                                                        {item.consecutiveItem}
                                                    </TableCellLayout>
                                                </TableCell>
                                                <TableCell>
                                                    <TableCellLayout className={`cell-bold ${item.isWarehouse?'cell-warehouse':'cell-store'}`}>
                                                        {item.warehouseType}
                                                    </TableCellLayout>
                                                </TableCell>
                                                <TableCell style={{overflow:"hidden", textOverflow:"ellipsis"}}>
                                                    {item.warehouseName}
                                                </TableCell>
                                                <TableCell >
                                                    <OmniTextBox 
                                                        name={"MIN"+item.idWarehouseStockItem}
                                                        onChange={onChangeMinimum}
                                                        blur={onBlurMinimum}
                                                        value={item.minimum} />
                                                </TableCell>
                                                <TableCell>
                                                    <OmniTextBox 
                                                        name={"MAX"+item.idWarehouseStockItem}
                                                        onChange={onChangeMaximum}
                                                        blur={onBlurMaximum}
                                                        value={item.maximum} />
                                                </TableCell>
                                                <TableCell>
                                                    <OmniTextBox 
                                                        name={"CAP"+item.idWarehouseStockItem}
                                                        onChange={onChangeCapacity}
                                                        blur={onBlurCapacity}
                                                        value={item.capacity} />
                                                </TableCell>
                                                <TableCell>
                                                    <TableCellLayout>
                                                         <OmniCheckBox 
                                                            onChange={onChangeCheckBoxRestock} 
                                                            name={item.idWarehouseStockItem} 
                                                            propertyChecked={item.restock} />   
                                                    </TableCellLayout>
                                                </TableCell>
                                                <TableCell >
                                                    <TableCellLayout>
                                                        {
                                                            item.isWarehouse == false
                                                            ?
                                                            <CompoundButton className="restocking-button">
                                                                Piezas
                                                            </CompoundButton>
                                                            : <></>
                                                        }
                                                    </TableCellLayout>
                                                </TableCell>
                                                <TableCell >
                                                    <TableCellLayout >
                                                        {
                                                            item.isWarehouse == false
                                                            ?<span>2</span>
                                                            :<></>
                                                        }
                                                    </TableCellLayout>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    </OmniCard>
                </div>
            </div>
        </div> 
    )
}