const branchGroupEndpoint = {
    controller: "branchgroup",
    actions: {
        getById: "GetById",
        getByStatus: "GetByStatus",
        add: "Add",
        update: "Update",
        setStatus: "SetStatus",
        getByActive:"GetByActive"
    }
}
/* group branch */
var axiosPrivate;
export const SetAxiosPrivate = (axiosPrivateParam) => {
    axiosPrivate = axiosPrivateParam;
}

export const GetByIdAsync = async (id) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(
            getUrl(branchGroupEndpoint.controller,
                branchGroupEndpoint.actions.getById).url,
            {
                params: { id: id }
            });
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    }
    catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const AddAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.post(
            getUrl(branchGroupEndpoint.controller,
                branchGroupEndpoint.actions.add).url,
            JSON.stringify({
                ...data,
                loggedUserName: localStorage.getItem("userName"),
            }));
        if (response.data.isSuccessful) {
            result.data = response.data.id;
            result.successMessage = `El grupo de sucursal se guardó exitosamente`;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};
export const UpdateAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(
            getUrl(branchGroupEndpoint.controller,
                branchGroupEndpoint.actions.update).url,
            JSON.stringify({
                ...data,
                loggedUserName: localStorage.getItem("userName"),
            })
        );
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `El grupo de sucursal se actualizó exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    }
    catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const UpdateStatusAsync = async (id, status) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(
            getUrl(branchGroupEndpoint.controller,
                branchGroupEndpoint.actions.UpdateStatusAsync).url,
            JSON.stringify({
                id: id,
                active: status,
                loggedUserName: localStorage.getItem("userName"),
            }));
        let statustext = status ? "activado" : "desactivado";
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.data = !status;
            result.successMessage = `El grupo de sucursal ha sido ${statustext} exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const GetByActiveAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getUrl(branchGroupEndpoint.controller, branchGroupEndpoint.actions.getByActive).url);
        if (response.data.isSuccessful) {
            if (response.data.data && response.data.data.length > 0) {
                result.data = response.data.data;
            }
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
function getUrl(controller, action) {
    var result = {
        isSuccessful: false,
        url: "",
        errorMessage: ""
    }
    if (controller && action) {
        result.url = ("/" + controller + "/" + action).toLowerCase();
    } else {
        result.errorMessage = "Controller or action undefined.";
    }

    return result;
}
const getErrorText = (err) => {
    if (!err?.response) {
        return "No server response";
    } else if (err.response?.status === 400) {
        return err.response?.data?.errorMessage
    }
    else if (err.response?.status === 500) {
        return err.response?.data?.detail;
    }
}