import { OmniTextBox } from '../../../Shared/Controls/TextBox/OmniTextBox';
import { OmniLabel } from '../../../Shared/Controls/Label/OmniLabel';
import {
    OverlayDrawer, DrawerBody, DrawerHeader, DrawerHeaderTitle,
    DrawerFooter, Image, CompoundButton
} from "@fluentui/react-components";
import BarMessageList from "../../../Shared/BarMessages/BarMessageList";
import { useState, useEffect } from 'react';
import { InfoMessage } from '../../../../Models/Shared/BarMessages/Messages';
const returnWhite = require('../../../../Assets/Images/Icons/ReturnWhite.svg').default;
const apply = require('../../../../Assets/Images/Icons/Apply.svg').default;
export const OverlayDrawerSearchIdentifier = (props) => {
    const [messages, setMessages] = useState([]);
    const onChangeSerieText = (event) => {
        const value = event.target.value.toUpperCase().trim();
        if (value) {
            props.setSearchIdentifier(prevEdit => ({ ...prevEdit, serie: value }));
        }
    };
    const onChangeFolioText = (event) => {
        const value = event.target.value.toUpperCase().trim();
        if (value) {
            props.setSearchIdentifier(prevEdit => ({ ...prevEdit, folio: value }));
        }
    };
    const handleClickSearch = () => {
        if (props.searchIdentifier?.serie === undefined) {
            setMessages([new InfoMessage("El campo serie es obligatorio")]);
        }
        else if (props.searchIdentifier?.folio === undefined) {
            setMessages([new InfoMessage("El campo folio es obligatorio")]);
        }
        else {
            props.setShowAttachIdentifierPanel(false);
            props.setSearchIdentifier(prevEdit => ({ ...prevEdit, apply: true }));
        }
    };
    useEffect(() => {
        setMessages([]);
        if (props.showAttachIdentifierPanel === true && props.documentList) {
            props.setSearchIdentifier(prevEdit => ({ ...prevEdit, folio: props.documentList[0].number, serie: props.documentList[0].serie }));
        }
    }, [props.showAttachIdentifierPanel])
    return (
        <OverlayDrawer position="end" open={props.showAttachIdentifierPanel} modalType="alert"
            style={{ width: '448px' }}>
            <DrawerHeader>
                <DrawerHeaderTitle>
                    Identificador
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody>
                <BarMessageList messages={messages} />
                <div className='row'>
                    <div className='col-md-12 mt-4'>
                        <OmniLabel text='Escriba la serie y folio del documento' />
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='col-12'>
                        {props.documentList && props.documentList.length > 1 ?
                            <OmniTextBox focus title="Serie" maxLength={3} onChange={onChangeSerieText} inactivefieldEdited reired />
                            :
                            <OmniLabel text="Serie" value={props.documentList ? props.documentList[0].serie: ''} />
                        }
                        
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='col-12'>
                        <OmniTextBox focus title="Folio" maxLength={12} onChange={onChangeFolioText} inactivefieldEdited reired value={props.documentList ? props.documentList[0].number : ''} />
                    </div>
                </div>
            </DrawerBody>
            <DrawerFooter >
                <div className="drawer-footer-container">
                    <CompoundButton id="btnCloseAttachSqlPanel" className="tile-button-panel"
                        icon={<Image src={returnWhite} alt="return" />}
                        iconPosition="after"
                        shape="square" size="small"
                        onClick={() => {
                            props.setShowAttachIdentifierPanel(false);
                            props.setSearchIdentifier(prevEdit => ({  folio: undefined,serie:undefined }));
                        }}
                    >Cerrar</CompoundButton>

                    <CompoundButton id="btnAddSqQueryPanel" className="tile-button-panel tile-button-panel-primary"
                        icon={<Image src={apply} alt="apply" />}
                        iconPosition="after"
                        shape="square" size="small"
                        onClick={handleClickSearch}
                    >Aplicar</CompoundButton>
                </div>
            </DrawerFooter>
        </OverlayDrawer>
    )
}