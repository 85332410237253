import CreditorAuthorizations from '../Authorizations/Creditor';
import DeviceTypeAuthorizations from '../Authorizations/DeviceType';
import CustomerTypeAuthorizations from '../Authorizations/CustomerType';
import DocumentAuthorizations from '../Authorizations/Document'
import IepsAuthorizations from '../Authorizations/Ieps';
import PreticketGroupAuthorizations from '../Authorizations/PreticketGroup'
import LinebusinessAuthorizations from '../Authorizations/Linebusiness';
import ItemAuthorizations from '../Authorizations/Item';
import SynchronizerExceptionAuthorizations from '../Authorizations/SynchronizerException';
import TransferRouteAuthorizations from '../Authorizations/TransferRoute';
import CampaignAuthorizations from '../Authorizations/Offers/OfferCampaigns';
import CustomerZoneAuthorizations from '../Authorizations/Configurations/CustomerZone';


export default function navLinkGroups() {
    return [
        {
            links: [
                {
                    name: 'Inicio',
                    iconOption: require('../../../Assets/Images/Icons/Home.svg').default,
                    urlOption: '/',
                    key: 'Index',
                },
                {
                    name: 'Catálogos',
                    iconOption: require('../../../Assets/Images/Icons/Catalog.svg').default,
                    key: 'cata',
                    isExpanded: false,
                    links: [
                        /*
                        {
                            name: 'Acreedores',
                            urlOption: '/catalogs/creditor',
                            key: CreditorAuthorizations.Index,
                        },
                        
                        {
                            name: 'Artículos',
                            urlOption: '/catalogs/item',
                            key: ItemAuthorizations.Index,
                        },
                        */
                        {
                            name: 'Bancos',
                            urlOption: '/catalogs/banks',
                            key: '772',
                        },
                        {
                            name: 'Categorías',
                            urlOption: '/catalogs/categories',
                            key: '21',
                        },
                        {
                            name: 'Clientes',
                            urlOption: '/catalogs/customers',
                            key: '1',
                        },
                        {
                            name: 'Conversiones',
                            urlOption: '/catalogs/convertItems',
                            key: '763',
                        },
                        {
                            name: 'Empleados',
                            urlOption: '/catalogs/Employees',
                            key: '11',
                        },
                        {
                            name: 'Familias',
                            urlOption: '/catalogs/family',
                            key: '186',
                        },
                        {
                            name: 'I.E.P.S.',
                            urlOption: '/catalogs/ieps',
                            key: IepsAuthorizations.Index,
                        },
                        {
                            name: 'Giro de negocio',
                            urlOption: '/catalogs/linebusiness',
                            key: LinebusinessAuthorizations.Index,
                        },
                        {
                            name: 'Marcas',
                            urlOption: '/catalogs/brand',
                            key: '536',
                        },
                        {
                            name: 'Medidas',
                            urlOption: '/catalogs/measures',
                            key: '445',
                        },
                        {
                            name: 'Proveedores',
                            urlOption: '/catalogs/suppliers',
                            key: '6',
                        },
                        {
                            name: 'Rutas de ventas',
                            urlOption: '/catalogs/routes',
                            key: '5',
                        },
                        {
                            name: 'Rutas de traspaso',
                            urlOption: '/catalogs/transferroute',
                            key: TransferRouteAuthorizations.Index,
                        },
                        {
                            name: 'Tipo de clientes',
                            urlOption: '/catalogs/customertypes',
                            key: CustomerTypeAuthorizations.Index,
                        },
                        {
                            name: 'Variedades',
                            urlOption: '/catalogs/variety',
                            key: '34',
                        },
                        {
                            name: 'Vehículos',
                            urlOption: '/catalogs/vehicles',
                            key: '531',
                        }
                        /*
                        {
                            name: 'Activos',
                            urlOption: '/catalogs/companyasset',
                            key: 'cata_activos',
                        },
                        {
                            name: 'Conversiones',
                            urlOption: '/',
                            key: 'cata_conversiones',
                        },
                        {
                            name: 'Empleados',
                            urlOption: '/',
                            key: 'cata_empleados',
                        },
                        {
                            name: 'Gastos',
                            urlOption: '/',
                            key: 'cata_gastos',
                        },
                        {
                            name: 'Impuestos',
                            urlOption: '/catalogs/accountingtaxes',
                            key: 'cata_impuestos',
                        },
                        {
                            name: 'Medidas',
                            urlOption: '/',
                            key: 'cata_medidas',
                        },    
                        {
                            name: 'Roles',
                            urlOption: '/',
                            key: 'cata_roles',
                        },
                        {
                            name: 'Rubros',
                            urlOption: '/catalogs/creditoraccount',
                            key: 'cata_rubros',
                        },
                        {
                            name: 'Subrubros',
                            urlOption: '/catalogs/creditorsubaccount',
                            key: 'cata_subrubros',
                        },
                        {
                            name: 'Productos y servicios',
                            urlOption: '/',
                            key: 'cata_productos',
                        },
                        {
                            name: 'S.A.T.',
                            urlOption: '/',
                            key: 'cata_sat',
                        },
                        {
                            name: 'Vendedores de ruta',
                            urlOption: '/',
                            key: 'cata_vendedores',
                        },
                        */
                    ],
                },
                {
                    name: 'Contabilidad',
                    iconOption: require('../../../Assets/Images/Icons/Accounting.svg').default,
                    key: '785',
                    isExpanded: false,
                    links: [
                        {
                            name: 'Póliza maestra',
                            urlOption: 'Accounting/masterpolicy/index',
                            key: '786',
                        },
                        {
                            name: 'Archivos XML',
                            urlOption: 'Accounting/xmlfile',
                            key: '802',
                        },
                    ]
                },
                {
                    name: 'Configuración',
                    iconOption: require('../../../Assets/Images/Icons/Configuration.svg').default,
                    isExpanded: false,
                    key: 'conf',
                    links:[
                        {
                            name: 'Almacenes',
                            urlOption: '/configuration/warehouse',
                            key: '16',
                        },
                        {
                            name: 'Choferes',
                            urlOption: '/configuration/driver', 
                            key: '526',
                        },
                        {
                            name: 'Dispositivos',
                            urlOption: '/configuration/devicetype',
                            key: DeviceTypeAuthorizations.Index,
                        },
                        {
                            name: 'Documentos',
                            urlOption: '/configuration/documents',
                            key: DocumentAuthorizations.Index,
                        },
                        {
                            name: 'Excepciones al sincronizador',
                            urlOption: '/configuration/synchronizerexception',
                            key: SynchronizerExceptionAuthorizations.Index,
                        },
                        /*
                        {
                            name: 'Grupos en preticket',
                            urlOption: '/configuration/preticketgroups',
                            key: PreticketGroupAuthorizations.Index,
                        },
                        */
                        {
                            name: 'Roles',
                            urlOption: '/configuration/roles',
                            key: 'Role',
                        },
                        {
                            name: 'S.A.T.',
                            urlOption: '/configuration/satcatalog',
                            key: 391,
                        },
                        {
                            name: 'Ubicaciones en almacén',
                            urlOption: '/configuration/warehouselocation',
                            key: '491',
                        },
                        {
                            name:'Zonas de clientes',
                            urlOption:'/configuration/customerzone',
                            key:CustomerZoneAuthorizations.Index
                        }
                    ]
                },
                /*
                {
                    name: 'Compras y pagos',
                    iconOption: require('../../../Assets/Images/Icons/Buys.svg').default,
                    key: 'comp',
                    links: [
                        {
                            name: 'Calendario de pagos',
                            urlOption: '/',
                            key: 'comp_calendario',
    
                        },
                        {
                            name: 'Compras directas',
                            urlOption: '/',
                            key: 'comp_compras',
    
                        },
                        {
                            name: 'NDC por descuento',
                            urlOption: '/',
                            key: 'comp_ndc_descuento',
    
                        },
                        {
                            name: 'NDC por devolución',
                            urlOption: '/',
                            key: 'comp_ndc_devolucion',
    
                        },
                        {
                            name: 'Pagos a proveedores',
                            urlOption: '/',
                            key: 'comp_pagos',
    
                        },
                        {
                            name: 'Órdenes de compra',
                            urlOption: '/',
                            key: 'comp_ordenes',
    
                        },
                    ]
                },
                {
                    name: 'Cupones generales',
                    iconOption: require('../../../Assets/Images/Icons/Coupon.svg').default,
                    key: 'cupo',
                    links: [
                        {
                            name: 'Descuento en el total',
                            urlOption: '/',
                            key: 'cupo_descuento_total',
                        },
                        {
                            name: 'Descuento en artículo',
                            urlOption: '/',
                            key: 'cupo_descuento_articulo',
                        },
                        {
                            name: 'Artículo de regalo',
                            urlOption: '/',
                            key: 'cupo_articulo',
                        },
                        {
                            name: 'Importe en el total',
                            urlOption: '/',
                            key: 'cupo_importe',
                        },
                    ]
                },
                {
                    name: 'Gastos',
                    iconOption: require('../../../Assets/Images/Icons/Bills.svg').default,
                    key: 'gast',
                    links: [
                        {
                            name: 'Presupuestos',
                            urlOption: '/',
                            key: 'gast_presupuestos',
                        },
                        {
                            name: 'Gastos',
                            urlOption: '/',
                            key: 'gast_gastos',
                        },
                        {
                            name: 'Gastos programados',
                            urlOption: '/',
                            key: 'gast_gastos_programados',
                        },
                    ]
                },
                */
                {
                    name: 'Ofertas',
                    iconOption: require('../../../Assets/Images/Icons/Offer.svg').default,
                    isExpanded: false,
                    key: 'ofer',
                    links: [
                        {
                            name: 'Campañas',
                            urlOption: '/offers/offerCampaign',
                            key: CampaignAuthorizations.Index,
                        }
                        //{
                        //    name: 'Ofertas por descuento',
                        //    urlOption: '/offers/discountoffer',
                        //    key: 999,
                        //}
                        /*
                        {
                            name: 'Combos',
                            urlOption: '/',
                            key: 'ofer_combos',
                        },
                        {
                            name: 'De mayoreo',
                            urlOption: '/',
                            key: 'ofer_mayoreo',
                        },
                        {
                            name: 'Por categorías',
                            urlOption: '/',
                            key: 'ofer_categorias',
                        },
                        {
                            name: 'Por descuento',
                            urlOption: '/',
                            key: 'ofer_descuento',
                        },
                        {
                            name: 'MxN',
                            urlOption: '/',
                            key: 'ofer_mxn',
                        },*/
                    ]
                },
                /*
                {
                    name: 'Reportes',
                    iconOption: require('../../../Assets/Images/Icons/Report.svg').default,
                    key: 'repo',
                    links: [
                        {
                            name: 'Asistencias',
                            urlOption: '/',
                            key: 'repo_asistencias',
                        },
                        {
                            name: 'Abonos',
                            urlOption: '/',
                            key: 'repo_abonos',
                        },
                        {
                            name: 'Gastos',
                            urlOption: '/',
                            key: 'repo_gastos',
                        },
                        {
                            name: 'Presupuestos',
                            urlOption: '/',
                            key: 'repo_presupuestos',
                        },
                        {
                            name: 'Saldos de proveedores',
                            urlOption: '/',
                            key: 'repo_saldos',
                        },
                        {
                            name: 'Utilidades',
                            urlOption: '/',
                            key: 'repo_utilidades',
                        },
                        {
                            name: 'Ventas',
                            urlOption: '/',
                            key: 'repo_ventas',
                        },
                    ]
                }, */
                
                {
                    name: 'Mapas',
                    iconOption: require('../../../Assets/Images/Icons/RoutesMap.svg').default,
                    key: 'map',
                    isExpanded: false,
                    links: [
                        {
                            name: 'Mapa de rutas',
                            urlOption: '/maps/routemap',
                            key: 'map',
                        }
                    ]
                },   
                {
                    name: 'Reportes',
                    iconOption: require('../../../Assets/Images/Icons/Report.svg').default,
                    isExpanded: false,
                    key: 'repor',
                    links: [
                        {
                            name: 'Asistencias',
                            urlOption: '/reports/attendancelog',
                            key: 599,
                        }
                    ]
                },   
                {
                    name: 'Cerrar sesión',
                    iconOption: require('../../../Assets/Images/Icons/Signoff.svg').default,
                    key: 'Logout',
                    id: 'Logout',
                },
            ],
        },
    ];
};