
import { Pivot, PivotItem } from "@fluentui/react";
import { useState, useEffect } from 'react';
import { Information } from './Information';
import { Discount } from './Discount';
import { OmniCallout } from '../../../Shared/Callout/OmniCallout';
import {
    leftButtons,
    rightButtons
} from '../../../../Assets/Constants/BarButtons/Offers/DiscountOffers/Add';
const pivotStyles = {
    root: {
        width: '95%'
    }
};
export const Index = (props) => {
    const [openCallout, setOpenCallout] = useState();
    useEffect(() => {
        props.setButtons({
            leftButtons: leftButtons(),
            rightButtons: rightButtons(null, () => {
                setOpenCallout(true);
            }),
        });
    }, []);


    function Return() {
        if (props.searchIdentifier?.serie && props.searchIdentifier?.folio) {
            props.setSearchIdentifier(prevEdit => ({ ...prevEdit, apply: true }));
        }
        else {
            props.setView(undefined);
            props.setDiscountOffer(undefined)
            setOpenCallout(false);
        } 
    }
    return (
        <div className="background-offer">
            <Pivot className="pivot-center-client"
                overflowBehavior="menu" styles={pivotStyles}          >
                <PivotItem headerText="Información" itemKey="information">
                    <Information discountOffer={props.discountOffer} setDiscountOffer={props.setDiscountOffer}
                        setButtonsSecond={props.setButtonsSecond} />
                 </PivotItem>
                <PivotItem headerText="Descuentos" itemKey="discount" >
                    <Discount discountOffer={props.discountOffer} setDiscountOffer={props.setDiscountOffer} />
                </PivotItem>
                <PivotItem headerText="Cambios" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }}>
                </PivotItem>
            </Pivot>
            {
                <OmniCallout
                    open={openCallout}
                    updateIsOpenAlert={setOpenCallout}
                    leftFunction={Return}
                    orientation={5}
                    title='Confirmación'
                    content={"¿Desea salir sin guardar los cambios?"}
                    leftButton={'Si'}
                    rightButton={'No'}
                    backgroundColorLeft={'var(--light-hover)'}
                    colorLeft={'var(--danger)'}
                    backgroundColorRight={'var(--light-hover)'}
                    colorRight={'var(--dark)'}
                    borderLeft={'none'}
                    borderRight={'none'}
                    buttonId={"BtnRegresar"}
                    barGroupKey={'rightCommandBar'}
                />

            }
        </div>
    )
}