import { useEffect, useState } from 'react';
import { Pivot, PivotItem } from '@fluentui/react';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Configurations/CustomersZones/Add';
import { SuccesMessage, DangerMessage, PrimaryMessage } from '../../../Models/Shared/BarMessages/Messages';
import { useGeneralStatesContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import Detail from './Detail';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
import { OmniCard } from "../../Shared/Card/OmniCard";
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import { AddAsync, SetAxiosPrivate } from '../../../Functions/Configuration/CustomerZones/CustomerZoneStore';
import { useFieldChangeWatcherContext } from "../../../Context/Controls/FieldChangeWatcherProvider"

const pivotStyles = {
    root: {
        width: '95%'
    }
};
const iconCatalog = {
    src: require('../../../Assets/Images/Icons/Routes.svg').default
}
export default function Add() {
    const fieldWatcher = useFieldChangeWatcherContext();
    const { register, handleSubmit, getValues, setValue } = useForm();
    const { setListMessageCatalog, addNewItem, useForeignKey } = useGeneralStatesContext();
    const changeComponent = useSetStatesContext();
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);
    const [openCallout, setOpenCallout] = useState();
    const[customerZone, setCustomerZone]=useState();
    

    const submit = async () => {
        setOpenCallout(true);
    };
    const AddConfirm = async () => {

        let errorMessages = fieldWatcher.ShowRequiredControls();
        if (errorMessages.length > 0) {
            setListMessageCatalog(listMessage => [...listMessage, new PrimaryMessage(errorMessages)]);
        }
        else {
            var data = getValues();
            let result = await AddAsync(data, useForeignKey);
            if (result.isSuccessful) {
                changeComponent(<Detail />, result.data);
                data.id = result.data;
                addNewItem({ ...data, acronym: localStorage.getItem("userName") });
                setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
            }
        }
    };
    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />
                <Pivot className="pivot-center-client " overflowBehavior="menu" styles={pivotStyles}>
                    <PivotItem headerText="Información">
                        <div className='container-fluid container-scroll-two-bars'>
                        <div className='row'>
                            <div className='col-md-auto'>
                                <OmniCard title='Datos generales'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox title='Nombre de la zona de clientes' maxLength={16} register={{ ...register('name') }} setValue={setValue} requiredOmni />
                                        </div>
                                    </div>
                                </OmniCard>
                            </div>
                        </div>
                        </div>
                    </PivotItem>
                    <PivotItem headerText="Clientes" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}></PivotItem>
                    <PivotItem headerText="Cambios" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                </Pivot>
            </form>
            <OmniCallout
                open={openCallout}
                updateIsOpenAlert={setOpenCallout}
                leftFunction={AddConfirm}
                title='Confirmación'
                content='¿Desea guardar los cambios?'
                leftButton={'Si'}
                rightButton={'Regresar'}
                backgroundColorLeft={'var(--success)'}
                colorLeft={'white'}
                backgroundColorRight={'var(--secondary)'}
                colorRight={'white'}
                buttonId={'BtnGuardar'}
                barGroupKey={'rigthCommandBar'}
            />
        </>
    )
}
