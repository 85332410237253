import { Pivot, PivotItem } from '@fluentui/react';
import { useEffect, useState } from 'react';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Configurations/CustomersZones/Detail';
import { useGeneralStatesContext, useIdSelectorContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import { GetByIdAsync, SetStatusAsync, SetAxiosPrivate } from '../../../Functions/Configuration/CustomerZones/CustomerZoneStore'
import { useFieldChangeWatcherContext } from '../../../Context/Controls/FieldChangeWatcherProvider';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { Detail as DetailChanges } from '../../Shared/UserChanges/Detail';
import { ElementsShareTableDetail } from '../../Shared/ElementsShareTable/ElementsShareTableDetail';
import CustomerZoneAuthorizations from '../../../Assets/Constants/Authorizations/Configurations/CustomerZone';

export default function Detail() {
    const id = useIdSelectorContext();
    const fieldWatcher = useFieldChangeWatcherContext();
    const { setListMessageCatalog, updateItemStatus, setLoadingSkeleton, useForeignKey } = useGeneralStatesContext();
    const [customerZone, setCustomerZone] = useState([]);
    const [buttonsCustomerZone, setButtonsCustomerZone] = useState({ leftButtons: leftButtons, rightButtons: rightButtons });
    const [changestatus, setChangestatus] = useState();
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);

    const getById = async () => {
        setLoadingSkeleton(true);
        let result = await GetByIdAsync(id);
        if (result.isSuccessful) {
            setCustomerZone(result.data);
            setLoadingSkeleton(false);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const updateStatus = async () => {
        let result = await SetStatusAsync(id, !customerZone.active);
        if (result.isSuccessful) {
            setCustomerZone((prev) => {
                return {
                    ...prev,
                    active: !customerZone.active,
                };
            });
            setChangestatus(true);
            setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    function ButtonActive() {
        const updatedButtons = leftButtons.map(button => {
            if (button.key === 'BtnStatus') {
                let statustextbutton = customerZone.active ? "Inactivar" : "Activar";
                let statusiconbutton = customerZone.active ? "inactiveCuvitek" : "activeCuvitek";
                let updatedAuthorizationKey = customerZone.active ? CustomerZoneAuthorizations.Delete : CustomerZoneAuthorizations.Reactive;
                return {
                    ...button, text: statustextbutton, iconName: statusiconbutton, authorizationKey: updatedAuthorizationKey,
                    alertprompt: `¿Desea ${statustextbutton.toLowerCase()} la zona de clientes?`,
                    handler: () => { updateStatus() }
                };
            }
            if (button.key === 'BtnEdit') {
                return {
                    ...button, disabled: !customerZone.active,
                };
            }
            return button;
        });
        return updatedButtons;
    }
    useEffect(() => {
        getById();
        fieldWatcher.hasPendingChangesRef.current = false;
        setChangestatus(undefined);
    }, [id]);
    useEffect(() => {
        if (customerZone) {
            setButtonsCustomerZone(prev => ({
                ...prev,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
        if (changestatus) {
            let data = { ...customerZone };
            updateItemStatus({ ...data, acronym: localStorage.getItem("userName") });
        }
    }, [customerZone?.active]);

    useEffect(() => {
        if (customerZone) {
            setButtonsCustomerZone(prev => ({
                ...prev,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
    }, [changestatus,id]);   

    return (
        <>
            {customerZone.length !== 0 &&
                (<BarButtons rightButtons={buttonsCustomerZone.rightButtons}
                leftButtons={buttonsCustomerZone.leftButtons} />)}
            <Pivot className="pivot-center-client">
                <PivotItem headerText="Información" >
            <div className='container-fluid container-scroll-two-bars'>
                <div className='row'>
                    <div className='col-md-auto'>
                        <OmniCard title='Datos generales'>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <OmniLabel text='Nombre' value={customerZone.name} />
                                </div>
                            </div>
                        </OmniCard>
                    </div>
                </div>
                    </div> 
                </PivotItem>
                <PivotItem headerText="Clientes">
                    <div className='container-fluid container-scroll-two-bars'>
                        <div className='row'>
                            <div className='col-md-auto'>
                                <OmniCard title='Clientes'>
                                    <div id='divItemsContainer' className='row card-items-container' >
                                        <div className='col-lg-12 col-md-12'>
                                            <ElementsShareTableDetail items={customerZone.customers} titleNameColumn="Clientes en la zona"/>
                                        </div>
                                    </div>
                                </OmniCard>
                            </div>
                        </div>
                    </div>
                </PivotItem>
                <PivotItem headerText="Cambios" itemKey="changes">
                    <DetailChanges user={customerZone} />
                </PivotItem>
            </Pivot>
        </>
    )
}