import {
    leftButtons,
    rightButtons,
    rightButtonsSecond,
    leftButtonsSecond,
    rightButtonsSecondAdd
} from '../../../Assets/Constants/BarButtons/Offers/DiscountOffers/DiscountOffer';
import {
    rightButtons as rightButtonsDetail
} from '../../../Assets/Constants/BarButtons/Offers/DiscountOffers/Detail';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { useState, useEffect } from 'react';
import { OverlayDrawerSearchIdentifier } from './Identifier/OverlayDrawerSearchIdentifier';
import { DangerMessage, SuccesMessage, PrimaryMessage } from '../../../Models/Shared/BarMessages/Messages';
import BarMessageList from "../../Shared/BarMessages/BarMessageList";
import {
    SetAxiosPrivate, GetByIdSerieAsync, UpdateCancelAsync
} from '../../../Functions/Offers/DiscountOffers/DiscountOfferStore';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate'
import '../../../Assets/Styles/Offers/DiscountOffers/DiscountOffer.css';
import { Index as IndexAdd } from './Add/Index';
import { Index as IndexDetail } from './Detail/Index';
import {
    SetAxiosPrivate as SetAxiosPrivateDocument, GetByDiscountOfferAsync
} from '../../../Functions/Configuration/Documents/DocumentStore';
export default function DiscountOffer() {
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);
    SetAxiosPrivateDocument(axiosPrivate);
    const [showAttachIdentifierPanel, setShowAttachIdentifierPanel] = useState(false);
    const [searchIdentifier, setSearchIdentifier] = useState({
        serie: undefined,
        folio: undefined
    });
    const [discountOffer, setDiscountOffer] = useState();
    const [messages, setMessages] = useState([]);
    const [view, setView] = useState();
    const [buttons, setButtons] = useState({
        leftButtons: leftButtons(),
        rightButtons: rightButtons(() => { handleClickNew() }, () => {
            setShowAttachIdentifierPanel(true);
        })
    });
    const [documentList, setDocumentList] = useState();
    const [buttonsSecond, setButtonsSecond] = useState();
    const handleClickCancel = async () => {
        let result = await UpdateCancelAsync(searchIdentifier);
       // if (result.isSuccessful) {
            setDiscountOffer((prev) => {
                return {
                    ...prev,
                    active: false,
                    updateUserFullName: localStorage.getItem("fullName"),
                    lastUpdateDate: new Date(),
                };
            });
            setMessages(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
        //}
        //else {
        //    setMessages(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        //}
    }
    const handleClickDuplicate = async () => {
        setDiscountOffer({
            serie: documentList[0].serie,
            folio: documentList[0].number,
            description: 'BBTIPS  T-4 GDE CHICOLASTIC C/40',
            createUserFullName: localStorage.getItem("fullName"),
            createDate: new Date(),
            offerType: '1',
            branchType: '1',
            branchGroupType: '1',
            branchesType: '1',
            discountType: '1',
            discount: '',
            startDate: new Date(),
            endDate: new Date(),
            active: true
        });
        setView(2);
    }
    const handleClickNew = async () => {
        var dataA = {
            serie: 1,
            folio: 1,
            createUserFullName: localStorage.getItem("fullName")
        }
        let result = await GetByIdSerieAsync(dataA);
        if (result.isSuccessful) {
            if (result.data) {
                result.data.serie = documentList[0].serie;
                result.data.folio = documentList[0].number;
                setDiscountOffer({
                    serie: documentList[0].serie,
                    folio:documentList[0].number,
                    createUserFullName: localStorage.getItem("fullName"),
                    createDate: new Date(),
                    offerType: '1',
                    branchType: '1',
                    branchGroupType: '1',
                    branchesType:'1',
                    discountType: '1',
                    discount: '',
                    startDate: new Date(),
                    endDate: new Date()
                });
                setButtonsSecond({
                    leftButtons: leftButtonsSecond(),
                    rightButtons: rightButtonsSecondAdd(result.data),
                });
                setView(2);
            }
            else {
                setMessages([new DangerMessage("No se encontraron resultados")]);
            }
        }
        else {
            setMessages([new DangerMessage(result.errorMessage)]);
        }
    }
    const GetByDiscountOffer = async () => {
        let result = await GetByDiscountOfferAsync();
        if (result.isSuccessful) {
            if (result.data && result.data.length > 0) {
                setDocumentList(result.data)
            }
            else {
                setMessages([new PrimaryMessage("Debe crear el documento 'Ofertas por descuento'")]);
            }
        }
    }
    const handleClickSearch = async () => {
        let result = await GetByIdSerieAsync(searchIdentifier);
            if (result.isSuccessful) {
                if (result.data) {
                    setDiscountOffer(result.data);
                    setButtonsSecond({
                        leftButtons: leftButtonsSecond(result.data.description),
                        rightButtons: rightButtonsSecond(result.data),
                    });
                    setButtons(prevEdit => ({
                        ...prevEdit,
                        rightButtons: rightButtonsDetail(() => { handleClickNew() }, () => {
                            setShowAttachIdentifierPanel(true);
                        }, result.data.active, () => { handleClickCancel() }, () => { handleClickDuplicate() })
                    }));
                    setView(1);
                }
                else {
                    setMessages([new DangerMessage("No se encontraron resultados")]);
                }
            }
            else {
                setMessages([new DangerMessage(result.errorMessage)]);
            }
    }
    useEffect(() => {
        if (discountOffer === undefined) {
            setButtons({
                leftButtons: leftButtons(),
                rightButtons: rightButtons(() => { handleClickNew() }, () => {
                    setShowAttachIdentifierPanel(true);
                })
            });
        }
    }, [discountOffer]);
    useEffect(() => {
        if (searchIdentifier?.apply === true) {
            setSearchIdentifier(prevEdit => ({ ...prevEdit, apply: false }));
            handleClickSearch();
        }
    }, [searchIdentifier]);
    useEffect(() => {
        if (discountOffer?.active === false) {
            setButtonsSecond(prevEdit => ({
                ...prevEdit,
                rightButtons: rightButtonsSecond(discountOffer),
            }));
            setButtons(prevEdit => ({
                ...prevEdit,
                rightButtons: rightButtonsDetail(() => { handleClickNew() }, () => {
                    setShowAttachIdentifierPanel(true);
                }, discountOffer.active, () => { handleClickCancel() }, () => { handleClickDuplicate() })
            }));
        }
    }, [discountOffer]);

    useEffect(() => {
        GetByDiscountOffer()
    }, [])
    useEffect(() => {
        if (documentList) {
            setButtons(prevEdit => ({
                ...prevEdit,
                rightButtons: rightButtons(() => { handleClickNew() }, () => {
                    setShowAttachIdentifierPanel(true);
                })
            }));
        }
    }, [documentList])
    return (
        <>
            <BarButtons leftButtons={buttons.leftButtons} rightButtons={buttons.rightButtons}
                leftButtonsClassName="col-4" rightButtonsClassName="col-8" />
            <BarMessageList messages={messages} />
            {discountOffer &&
                <BarButtons rightButtons={buttonsSecond.rightButtons}
                    leftButtons={buttonsSecond.leftButtons} className="background-color-light-hover border-gray"
                    leftButtonsClassName="col-lg-3 col-md-3" rightButtonsClassName="col-lg-9 col-md-9" />
            }
            {view === 1 ? <IndexDetail discountOffer={discountOffer} /> :
                view === 2 ? <IndexAdd discountOffer={discountOffer}
                    setDiscountOffer={setDiscountOffer}
                    setButtons={setButtons}
                    setButtonsSecond={setButtonsSecond}
                    setView={setView}
                    searchIdentifier={searchIdentifier}
                    setSearchIdentifier={setSearchIdentifier} documentList={documentList}
                /> :
                    <div className="background-offer">
            </div>
            }
            <OverlayDrawerSearchIdentifier showAttachIdentifierPanel={showAttachIdentifierPanel}
                setShowAttachIdentifierPanel={setShowAttachIdentifierPanel}
                setSearchIdentifier={setSearchIdentifier}
                searchIdentifier={searchIdentifier} documentList={documentList} />
        </>
    )
}