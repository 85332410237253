import {
    leftButtons,
    rightButtons,
    rightButtonsSecond,
    rightButtonsDisabled,
    rightButtonsSecondDisabled,
    rightButtonsExec
} from '../../../Assets/Constants/BarButtons/Accounting/XMLFile/XMLFile';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate'
import BarButtons from '../../Shared/BarButtons/BarButtons';
import '../../../Assets/Styles/Accounting/Accounting.css';
import '../../../Assets/Styles/Shared/Table/DetailTable.css';
import { useState, useEffect, useRef } from 'react';
import {
    SetAxiosPrivate, GetDate, DateDefaultDisabled, DateDefault, GetByFilterAsync,
    LastDate, GetLastDate, ToShortDateNow, GetFileXmlByIdAsync, UpdateStatusByIdAsync, GetFileZipByIdAsync
} from '../../../Functions/Accounting/XMLFile/XMLFileStore';
import {
    SetAxiosPrivate as SetAxiosPrivateSupplier, GetByActiveAsync
} from '../../../Functions/Catalogs/Suppliers/SupplierStore';
import {
    SetAxiosPrivate as SetAxiosPrivateRole, GetDaysDocumentByRolIdAsync
} from '../../../Functions/Configuration/Roles/RoleStore';
import { DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import BarMessageList from "../../Shared/BarMessages/BarMessageList";
import { OverlayDrawerDate } from '../../Shared/OverlayDrawer/OverlayDrawerDate';
import { OverlayDrawerSupplier } from '../../Shared/OverlayDrawer/OverlayDrawerSupplier';
import { OverlayDrawerStatusXml, ItemsStatus } from '../../Shared/OverlayDrawer/OverlayDrawerStatusXml';
import { TableBody, TableCell, TableRow, Table, TableHeader,
    TableHeaderCell, TableCellLayout, Popover,
    PopoverSurface,
    PopoverTrigger
} from "@fluentui/react-components";
import { ReactComponent as XmlFile } from '../../../Assets/Images/Icons/XmlFile.svg';
import { ReactComponent as Download } from '../../../Assets/Images/Icons/Download.svg';
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import * as XLSX from 'xlsx-color';
import { PaginateTable } from '../../Shared/Paginate/PaginateTable';
import { Pivot, PivotItem } from '@fluentui/react';
export default function XMLFiles() {
    const columnsHeader = [
        { columnKey: "1", label: "Fecha", width: '10%' },
        { columnKey: "2", label: "Documento", width: '5%' },
        { columnKey: "2", label: "AP", width: '5%' },
        { columnKey: "3", label: "RFC", width: '10%' },
        { columnKey: "4", label: "Razón social", width: '15%' },
        { columnKey: "5", label: "Subtotal", width: '10%' },
        { columnKey: "6", label: "IVA", width: '10%' },
        { columnKey: "7", label: "IEPS", width: '10%' },
        { columnKey: "8", label: "Total", width: '10%' },
        { columnKey: "9", label: " ", width: '5%' },
        { columnKey: "10", label: "Estatus SAT", width: '10%' },
    ];
    const axiosPrivate = useAxiosPrivate();
    const tableRefSupplier = useRef(null);
    const tableRefCreditor = useRef(null);
    SetAxiosPrivate(axiosPrivate);
    SetAxiosPrivateSupplier(axiosPrivate);
    SetAxiosPrivateRole(axiosPrivate);
    const [messages, setMessages] = useState([]);
    const [showAttachSupplierPanel, setShowAttachSupplierPanel] = useState(false);
    const [showAttachStatusPanel, setShowAttachStatusPanel] = useState(false);
    const [showAttachDatePanel, setShowAttachDatePanel] = useState(false);
    const [policylistSupplier, setPolicylistSupplier] = useState();
    const [policylistCreditor, setPolicylistCreditor] = useState();
    const [lastDate, setlastDate] = useState();
    const [contract, setContract] = useState(true);
    const [supplierList, setSupplierList] = useState([]);
    const [totalPaginate, setTotalPaginate] = useState([]);
    const [totalPaginateSupplier, setTotalPaginateSupplier] = useState([]);
    const [totalPaginateCreditor, setTotalPaginateCreditor] = useState([]);
    const [foundValueSupplier, setFoundValueSupplier] = useState();
    const [foundValueCreditor, setFoundValueCreditor] = useState();
    const [daysDocument, setDaysDocument] = useState();
    const [param, setParam] = useState({
        startDate: GetDate().startDate,
        endDate: GetDate().endDate,
        supplierType: "1",
        status: []
    });
    const handleClickNewQuery = () => {
        setParam({
            startDate: GetDate().startDate,
            endDate: GetDate().endDate,
            supplierType: "1",
            status: []
        });
        setSupplierList(supplierList.map((supplier) => ({
            ...supplier,
            selected: false,
        })));
        setPolicylistSupplier(undefined);
        setPolicylistCreditor(undefined);
        setTotalPaginate([]);
        setTotalPaginateSupplier([]);
        setTotalPaginateCreditor([]);
    }
    const HandleClickContract = () => {
        setContract(!contract)
    }

    const UpdateButtons = () => {
        if (totalPaginate && totalPaginate.length > 0) {
            setButtons(prevEdit => ({
                ...prevEdit,
                rightButtons: rightButtonsExec(() =>
                    handleClickExcel(totalPaginate), () => handleClickDownload(), handleClickNewQuery, LastDate(lastDate), contract, HandleClickContract)
            }));
        }
        else {
            setButtons(prevEdit => ({
                ...prevEdit,
                rightButtons: rightButtons(handleClickTrigger, handleClickNewQuery, LastDate(lastDate), contract, HandleClickContract, daysDocument)
            }));
        }
    }
    const handleClickTrigger = async () => {
        setButtons(prevEdit => ({
            ...prevEdit,
            rightButtons: rightButtonsDisabled(handleClickTrigger, LastDate(lastDate), contract, HandleClickContract)
        }));
        if (param.startDate.setHours(0, 0, 0, 0) > param.endDate.setHours(0, 0, 0, 0)) {
            setMessages([new DangerMessage("La fecha inicial no puede ser mayor a la fecha final")]);
        }
        else {
            let result = await GetByFilterAsync(param, supplierList.filter(x => x.selected).map(x => x.rfc), param.status.map(x => x.value));
            if (result.isSuccessful) {
                if (result.data && result.data.length > 0) {
                    setTotalPaginate(result.data);
                    setTotalPaginateSupplier(result.data.filter(x => x.statusControl !== 5));
                    setTotalPaginateCreditor(result.data.filter(x => x.statusControl === 5));
                    setPolicylistSupplier(result.data.filter(x => x.statusControl !== 5).slice(0, 50));
                    setPolicylistCreditor(result.data.filter(x => x.statusControl === 5).slice(0, 50));
                    setButtonsSecond({
                        rightButtons: rightButtonsSecondDisabled(
                            DateDefaultDisabled(param),
                            supplierList.filter(x => x.selected).map(x => x.name),
                            param.status.map(x => x.description))
                    });
                    setButtons(prevEdit => ({
                        ...prevEdit,
                        rightButtons: rightButtonsExec(() =>
                            handleClickExcel(result.data), () => handleClickDownload(), handleClickNewQuery, LastDate(lastDate), contract, HandleClickContract)
                    }));
                }
                else {
                    setMessages([new DangerMessage("No se encontraron resultados")]);
                    UpdateButtons();
                }
            }
            else {
                setMessages([new DangerMessage(result.errorMessage)]);
                UpdateButtons();
            }
        }
    }
    const [buttons, setButtons] = useState({
        leftButtons: leftButtons(),
        rightButtons: []
    });
    const [buttonsSecond, setButtonsSecond] = useState({
        rightButtons: []
    });
    const GetSuppliersByStatusActive = async () => {
        let result = await GetByActiveAsync();
        if (result.isSuccessful) {
            setSupplierList(result.data);
        }
        else {
            setMessages([DangerMessage(result.errorMessage)]);
        }
    }
    const GetLastDateUpdate = async () => {
        let result = await GetLastDate();
        if (result.isSuccessful) {
            setlastDate(result.data);
        }
    }
    const GetDaysDocumentByRolId = async () => {
        let result = await GetDaysDocumentByRolIdAsync();
        if (result.data === -1) {
            setMessages([new DangerMessage("No tienes permisos para generar el reporte")]);
        }
        setDaysDocument(result.data);
    }
    useEffect(() => {
        GetSuppliersByStatusActive();
        GetLastDateUpdate();
        GetDaysDocumentByRolId();
    }, [])
    const UpdateButtonsSecond = () => {
        setButtonsSecond({
            rightButtons: rightButtonsSecond(
                () => {
                    setShowAttachDatePanel(true);
                }, () => {
                    setShowAttachSupplierPanel(true);
                },
                () => {
                    setShowAttachStatusPanel(true);
                },
                DateDefault(param),
                supplierList.filter(x => x.selected).map(x => x.name),
                param.status.map(x => x.description))
        });
    }
    useEffect(() => {
        UpdateButtons();
    }, [contract]);
    useEffect(() => {
        UpdateButtons();
        UpdateButtonsSecond();
        setPolicylistSupplier(undefined);
        setPolicylistCreditor(undefined);
    }, [param]);
    useEffect(() => {
        if (lastDate) {
            UpdateButtons();
        }
    }, [lastDate]);
    useEffect(() => {
        if (supplierList) {
            UpdateButtonsSecond();
            UpdateButtons();
        }
    }, [supplierList]);
    useEffect(() => {
        if (daysDocument) {
            UpdateButtons();
        }
    }, [daysDocument]);
    const handleClickDownload = async () => {
        let result = await GetFileZipByIdAsync(param, supplierList.filter(x => x.selected).map(x => x.rfc), param.status.map(x => x.value));
        if (result && result.data) {
            const urlImagen = URL.createObjectURL(result.data);
            const link = document.createElement("a");
            link.download = `CFDI ${ToShortDateNow()}.zip`;
            link.href = urlImagen;
            link.click();
        }
        else {
            setMessages([new DangerMessage("No se encontraron resultados")]);
        }
    }
    const handleClickExcel = (data) => {
        const modifiedData = data.map(item => ({
            date: item.date,
            document: ItemsStatus.find(x => x.value == item.statusControl)?.description,
            ap: item.statusControl === 5 ? 'A' : 'P',
            rfc: item.rfc,
            issuerName: item.issuerName,
            amount: item.amount.replace(/\$|,/g, ''),
            ivaAmount: item.ivaAmount.replace(/\$|,/g, ''),
            iepsAmount: item.iepsAmount.replace(/\$|,/g, ''),
            netAmount: item.netAmount.replace(/\$|,/g, ''),
            status: item.status === 'V' ? 'Vigente' : 'Cancelado'
        }));
        let Heading = [['Fecha', 'Documento', 'AP', 'RFC', 'Razón social', 'Subtotal', 'IVA', 'IEPS', 'Total', 'Estatus SAT']];
        const wb = XLSX.utils.book_new();
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(ws, Heading);
        XLSX.utils.sheet_add_json(ws, modifiedData, { origin: 'A2', skipHeader: true });
        XLSX.utils.book_append_sheet(wb, ws, 'Hoja1');
        XLSX.writeFile(wb, `CFDI ${ToShortDateNow()}.xlsx`);
    }

    const handleClickUpdateXml = async (id) => {
        let result = await UpdateStatusByIdAsync(id);
        let data = [];
        if (result.isSuccessful) {
            if (selectedKey === "supplier") {
                setPolicylistSupplier((prev) => {
                    const itemIndex = prev.findIndex((item) => item.id === id);

                    if (itemIndex !== -1) {
                        const updatedItem = { ...prev[itemIndex], statusControl: 4 };
                        return [...prev.slice(0, itemIndex), updatedItem, ...prev.slice(itemIndex + 1)];
                    }

                    return prev;
                });
                setTotalPaginateSupplier((prev) => {
                    const itemIndex = prev.findIndex((item) => item.id === id);

                    if (itemIndex !== -1) {
                        const updatedItem = { ...prev[itemIndex], statusControl: 4 };
                        return [...prev.slice(0, itemIndex), updatedItem, ...prev.slice(itemIndex + 1)];
                    }

                    return prev;
                })
            }
            else {
                setPolicylistCreditor((prev) => {
                    const itemIndex = prev.findIndex((item) => item.id === id);

                    if (itemIndex !== -1) {
                        const updatedItem = { ...prev[itemIndex], statusControl: 4 };
                        return [...prev.slice(0, itemIndex), updatedItem, ...prev.slice(itemIndex + 1)];
                    }

                    return prev;
                });
                setTotalPaginateCreditor((prev) => {
                    const itemIndex = prev.findIndex((item) => item.id === id);

                    if (itemIndex !== -1) {
                        const updatedItem = { ...prev[itemIndex], statusControl: 4 };
                        return [...prev.slice(0, itemIndex), updatedItem, ...prev.slice(itemIndex + 1)];
                    }

                    return prev;
                })
            }
            setTotalPaginate((prev) => {
                const itemIndex = prev.findIndex((item) => item.id === id);

                if (itemIndex !== -1) {
                    const updatedItem = { ...prev[itemIndex], statusControl: 4 };
                    data = [...prev.slice(0, itemIndex), updatedItem, ...prev.slice(itemIndex + 1)]
                    return data;
                }

                return prev;
            });
            setButtons(prevEdit => ({
                ...prevEdit,
                rightButtons: rightButtonsExec(() =>
                    handleClickExcel(data), () => handleClickDownload(), handleClickNewQuery, LastDate(lastDate), contract, HandleClickContract)
            }));
        }
        else {
            setMessages([DangerMessage(result.errorMessage)]);
        }

    }
    const PopoverRender = (content, className, item) => {
        if (item.statusControl === 2) {
            return <Popover size="small" positioning="below">
                <PopoverTrigger >
                    <TableCell>
                        <TableCellLayout className={className} >
                            {content}
                        </TableCellLayout>
                    </TableCell>

                </PopoverTrigger>
                <PopoverSurface tabIndex={-1} className="popover" onClick={() => handleClickUpdateXml(item.id)}>
                    <OmniLabel text={'Omitir XML'} />
                </PopoverSurface>
            </Popover>
        }
        else {
            return <TableCell>
                <TableCellLayout className={className} >
                    {content}
                </TableCellLayout>
            </TableCell>
        }
    }
    const handleClickDownloadXml = async (id, uuid) => {
        let result = await GetFileXmlByIdAsync(id);
        if (result && result.data) {
            const urlImagen = URL.createObjectURL(result.data);
            const link = document.createElement("a");
            link.download = `${uuid}.xml`;
            link.href = urlImagen;
            link.click();
        }
        else {
            setMessages([new DangerMessage("No se encontraron resultados")]);
        }

    }
    const renderPolicyRow = (item, index) => {
        const tableRow = (
            <TableRow key={index} className={`row-${item.id} ${selectedKey === "supplier" ? item.found !== undefined && (foundValueSupplier === item.found) ? 'item-found' : '' : item.found !== undefined && (foundValueCreditor === item.found) ? 'item-found' : ''}`}>
                {PopoverRender(item.date, "justify-content-center", item)}
                {PopoverRender(<XmlFile />, `justify-content-center ${ItemsStatus.find(x => x.value == item.statusControl).className}`, item)}
                {PopoverRender(item.statusControl === 5 ? 'A' : 'P', "justify-content-center", item)}
                {PopoverRender(item.rfc, "justify-content-center", item)}
                {PopoverRender(item.issuerName, "justify-content-start", item)}
                {PopoverRender(item.amount, "justify-content-end", item)}
                {PopoverRender(item.ivaAmount, "justify-content-end", item)}
                {PopoverRender(item.iepsAmount, "justify-content-end", item)}
                {PopoverRender(item.netAmount, "justify-content-end", item)}
                <TableCell onClick={() => handleClickDownloadXml(item.blobStorageId, item.uuid)}>
                    <TableCellLayout className={`justify-content-center color-secondary-fill`}>
                        <Download />
                    </TableCellLayout>
                </TableCell>
                {PopoverRender(item.status, `justify-content-center ${item.status === 'V' ? 'color-success' : 'color-danger'}`, item)}
            </TableRow>
        );
        return tableRow;
    };
    useEffect(() => {
        if (selectedKey === "supplier") {
            if (totalPaginateSupplier.length > 0 && foundValueSupplier && policylistSupplier?.length > 0) {
                setPolicylistSupplier(prevEdit => prevEdit.map(item => {
                    const matchingItem = totalPaginateSupplier.find(x => x.id === item.id);
                    return {
                        ...item,
                        found: matchingItem.found
                    };
                }));
            }
        }
       
    }, [totalPaginateSupplier])
    useEffect(() => {
        if (selectedKey === "creditor") {
            if (totalPaginateCreditor.length > 0 && foundValueCreditor && policylistCreditor?.length > 0) {
                setPolicylistCreditor(prevEdit => prevEdit.map(item => {
                    const matchingItem = totalPaginateCreditor.find(x => x.id === item.id);
                    return {
                        ...item,
                        found: matchingItem.found
                    };
                }));
            }
        }

    }, [totalPaginateCreditor])
    useEffect(() => {
        if (selectedKey === "supplier") {
            if (foundValueSupplier > 0 && policylistSupplier && policylistSupplier.length > 0) {
                let foundId = totalPaginateSupplier.find(x => x.found === foundValueSupplier)
                if (foundId) {
                    const targetRow = tableRefSupplier.current.querySelector(`.row-${foundId.id}`);
                    if (targetRow) {
                        targetRow.scrollIntoView({ behavior: 'smooth' });
                    }
                }
            }
        }
    }, [foundValueSupplier, policylistSupplier]);
    useEffect(() => {
        if (selectedKey === "creditor") {
            if (foundValueCreditor > 0 && policylistCreditor && policylistCreditor.length > 0) {
                let foundId = totalPaginateCreditor.find(x => x.found === foundValueCreditor)
                if (foundId) {
                    const targetRow = tableRefCreditor.current.querySelector(`.row-${foundId.id}`);
                    if (targetRow) {
                        targetRow.scrollIntoView({ behavior: 'smooth' });
                    }
                }
            }
        }
    }, [foundValueCreditor, policylistCreditor]);
    const [selectedKey, setSelectedKey] = useState("supplier");
    const handleLinkClick = (item) => {
        if (item) {
            setSelectedKey(item.props.itemKey);
        }
    };
    console.log(policylistSupplier)
    return (
        <>
            <BarButtons rightButtons={buttons.rightButtons}
                leftButtons={buttons.leftButtons} className="background-color-light-hover border-gray"
                leftButtonsClassName="col-lg-4 col-md-4" rightButtonsClassName="col-lg-8 col-md-8" />
            <BarButtons rightButtons={buttonsSecond.rightButtons} className="background-color-light-hover barButtons" />
            <BarMessageList messages={messages} />

            <div className='row'>
                <div className='col-lg-12 col-md-12 '>
                    {(policylistSupplier || policylistCreditor) &&
                        <Pivot className="pivot-center-xmlfile" selectedKey={selectedKey} onLinkClick={handleLinkClick}>
                            <PivotItem headerText="Proveedores" itemKey="supplier" >
                                {policylistSupplier &&
                                    <>
                                        <div className="container-table-paginate-xmlfile">
                                        <Table size="small" className="table table-hover table-updater" ref={tableRefSupplier}>
                                                <TableHeader className="header-fixed">
                                                    <TableRow>
                                                        {columnsHeader.map((column) => (
                                                            <TableHeaderCell key={column.columnKey}
                                                                style={{ width: column.width }}
                                                                className={'detail-table-header-title ' + column?.classAdd}>
                                                                {column.label}
                                                            </TableHeaderCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHeader>
                                                <TableBody>
                                                    {
                                                        policylistSupplier.map(renderPolicyRow)
                                                    }
                                                </TableBody>
                                            </Table>
                                        </div>
                                    <PaginateTable totalPaginate={totalPaginateSupplier}
                                        setTotalPaginate={setTotalPaginateSupplier}
                                        setItemsList={setPolicylistSupplier} totalPaginateLength={totalPaginateSupplier.length}
                                        foundValue={foundValueSupplier}
                                        setFoundValue={setFoundValueSupplier} />
                                    </>
                                }
                            </PivotItem>
                            <PivotItem headerText="Acreedores" itemKey="creditor" >
                                {policylistCreditor &&
                                    <>
                                        <div className="container-table-paginate-xmlfile">
                                        <Table size="small" className="table table-hover table-updater" ref={tableRefCreditor}>
                                                <TableHeader className="header-fixed">
                                                    <TableRow>
                                                        {columnsHeader.map((column) => (
                                                            <TableHeaderCell key={column.columnKey}
                                                                style={{ width: column.width }}
                                                                className={'detail-table-header-title ' + column?.classAdd}>
                                                                {column.label}
                                                            </TableHeaderCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHeader>
                                                <TableBody>
                                                    {
                                                        policylistCreditor.map(renderPolicyRow)
                                                    }
                                                </TableBody>
                                            </Table>
                                        </div>
                                    <PaginateTable totalPaginate={totalPaginateCreditor}
                                        setTotalPaginate={setTotalPaginateCreditor}
                                        setItemsList={setPolicylistCreditor} totalPaginateLength={totalPaginateCreditor.length}
                                        foundValue={foundValueCreditor}
                                        setFoundValue={setFoundValueCreditor} />
                                    </>
                                }
                            </PivotItem>
                        </Pivot>

                    }
                    
                </div>
            </div>
            <OverlayDrawerDate showAttachDatePanel={showAttachDatePanel}
                setShowAttachDatePanel={setShowAttachDatePanel}
                setParam={setParam}
                param={param} daysDocument={daysDocument} />
            <OverlayDrawerSupplier showAttachSupplierPanel={showAttachSupplierPanel}
                setShowAttachSupplierPanel={setShowAttachSupplierPanel}
                setParam={setParam}
                param={param}
                supplierList={supplierList}
                setSupplierList={setSupplierList} />
            <OverlayDrawerStatusXml showAttachStatusPanel={showAttachStatusPanel}
                setShowAttachStatusPanel={setShowAttachStatusPanel}
                setParam={setParam}
                param={param} />
        </>
    )
}