import { Pivot, PivotItem } from "@fluentui/react";
import { Information } from './Information';
import { Discount } from './Discount';
import { Detail as DetailChanges } from '../../../Shared/UserChanges/Detail';
const pivotStyles = {
    root: {
        width: '95%'
    }
};
export const Index = (props) => {
   
    return (
            <div className="background-offer">
                <Pivot className="pivot-center-client"
                    overflowBehavior="menu" styles={pivotStyles}          >
                    <PivotItem headerText="Información" itemKey="information">
                        <Information discountOffer={props.discountOffer} 
                            setButtonsSecond={props.setButtonsSecond} />
                    </PivotItem>
                    <PivotItem headerText="Descuentos" itemKey="discount" >
                        <Discount discountOffer={props.discountOffer}  />
                    </PivotItem>
                <PivotItem headerText="Cambios" itemKey="changes">
                    <DetailChanges user={props.discountOffer} />
                </PivotItem>
                </Pivot>
            </div>
    )
}